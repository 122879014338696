import React from "react";
import { Grid, Tab, Form, Input, Table, Button } from "semantic-ui-react";

import { WithAuthService } from "services";
import { AuthContext } from "components/utils/AuthorizationContext/AuthorizationContext";
import { UserLink } from "../ui-elements";

class SellOrderRow extends React.Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.state = {
      buyRowOpen: false,
      loading: false,
      PurchaseAmount: "",
      ownListing: this.props.ownListing,
      ID: this.props.listing.ID,
      UnitPrice: this.props.listing.UnitPrice,
      PlayerName: this.props.listing.PlayerName,
      Amount: this.props.listing.Amount,
    };
  }

  toggleBuyRow = () => {
    this.setState({
      buyRowOpen: !this.state.buyRowOpen,
    });
  };

  onChange = (e) => {
    let value = parseInt(e.target.value);
    if (!value) {
      value = 0;
    }

    if (value >= this.state.Amount) {
      value = this.state.Amount;
    }

    this.setState({
      PurchaseAmount: value,
    });
  };

  buyFromListing = () => {
    WithAuthService.doRequest({
      method: "POST",
      url: `exchange/buy-from-listing/${this.state.ID}`,
      body: {
        Amount: this.state.PurchaseAmount,
      },
    })
      .then((response) => {
        if (response.result.status === 200) {
          this.context.updatePlayer(response.result.body);
          this.props.reloadListings();
          return;
        }

        this.context.setError(response.result.body.Message, 1000);
      })
      .catch((error) => {
        this.context.setError(error, 1000);
      });
  };

  cancelListing = () => {
    // TODO let the user confirm their cancellation

    WithAuthService.doRequest({
      method: "DELETE",
      url: `exchange/cancel-listing/${this.state.ID}`,
    }).then((response) => {
      this.context.updatePlayer(response.result.body);
      this.props.reloadListings();
    });
  };

  maxAmount = () => {
    let playerCredits = this.context.player.Credits;
    let maxAmount = parseInt(playerCredits / this.state.UnitPrice);
    if (maxAmount > this.state.Amount) maxAmount = this.state.Amount;
    this.setState({
      PurchaseAmount: maxAmount,
    });
  };

  render() {
    let { ownListing, loading, Amount, PlayerName, UnitPrice, PurchaseAmount } = this.state;
    let classNames = this.state.buyRowOpen ? "" : "invisible";

    return (
      <>
        <Table.Row>
          <Table.Cell>{Amount.toLocaleString("nl-NL")}</Table.Cell>
          <Table.Cell>{UnitPrice.toLocaleString("nl-NL")}</Table.Cell>
          <Table.Cell>
            <UserLink brackets={false} username={PlayerName} />
          </Table.Cell>
          <Table.Cell>
            {!ownListing && <Button size="tiny" disabled={false} compact onClick={this.toggleBuyRow} content="Buy" />}
            {ownListing && <Button size="tiny" compact onClick={this.cancelListing} content="Cancel" />}
          </Table.Cell>
        </Table.Row>
        <Table.Row className={classNames}>
          <Table.Cell colSpan={4} textAlign="center">
            <Input
              size="small"
              onChange={this.onChange}
              disabled={loading}
              value={PurchaseAmount}
              placeholder="How many?"
              action={
                <Button size="small" onClick={this.maxAmount}>
                  Max
                </Button>
              }
            />

            <br />
            <br />

            <Button size="small" disabled={ownListing} onClick={this.buyFromListing}>
              Buy
            </Button>

            <h5>
              Total price: <strong>{(PurchaseAmount * UnitPrice).toLocaleString("nl-NL")}</strong> credits
            </h5>
          </Table.Cell>
        </Table.Row>
      </>
    );
  }
}

export class CurrencyExchangePage extends React.Component {
  static contextType = AuthContext;

  state = {
    loading: false,

    NewNeutroniumListingAmount: "",
    NewNeutroniumListingUnitPrice: "",
    NewSpaceDebrisListingAmount: "",
    NewSpaceDebrisListingUnitPrice: "",
    NeutroniumSellOrders: [],
    SpaceDebrisSellOrders: [],
  };

  componentDidMount() {
    this.loadSellOrders("neutronium");
    this.loadSellOrders("space_debris");
  }

  loadSellOrders = (type = "neutronium") => {
    this.setState({ loading: true });

    WithAuthService.doRequest({
      method: "GET",
      url: `exchange/listings/${type}`,
    })
      .then((response) => {
        if (response.result.status !== 200 && response.result.body.Message) {
          this.context.setError(response.result.body.Message, 1000);
          return;
        }

        if (type === "neutronium") {
          this.setState({
            NeutroniumSellOrders: response.result.body,
          });
        } else {
          this.setState({
            SpaceDebrisSellOrders: response.result.body,
          });
        }

        this.setState({ loading: false });
      })
      .catch((error) => {
        this.setState({ loading: false });
        this.context.setError(error, 1000);
      });
  };

  addListing = (currencyType) => {
    let amount,
      price = 0;
    if (currencyType === "neutronium") {
      amount = this.state.NewNeutroniumListingAmount;
      price = this.state.NewNeutroniumListingUnitPrice;
    } else {
      amount = this.state.NewSpaceDebrisListingAmount;
      price = this.state.NewSpaceDebrisListingUnitPrice;
    }

    if (amount <= 0 || price <= 0) {
      this.context.setError("Amount and unit price must be at least 1 each", 1000);
      return;
    }

    this.setState({ loading: true });

    WithAuthService.doRequest({
      method: "POST",
      url: `exchange/new-listing`,
      body: {
        CurrencyType: currencyType,
        Amount: amount,
        UnitPrice: price,
      },
    })
      .then((response) => {
        if (response.result.status === 200) {
          this.setState({
            NewSpaceDebrisListingAmount: "",
            NewSpaceDebrisListingUnitPrice: "",
            NewNeutroniumListingAmount: "",
            NewNeutroniumListingUnitPrice: "",
          });
          this.context.updatePlayer(response.result.body);
          this.loadSellOrders(currencyType);
          return;
        }

        this.context.setError(response.result.body.Message, 1000);
      })
      .catch((error) => {
        this.context.setError(error, 1000);
      });

    this.setState({ loading: false });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    const numberRegex = /^[0-9\b]+$/;
    if (value === "" || numberRegex.test(value)) {
      this.setState({ [name]: parseInt(value, 10) });
    }
  };

  render() {
    let {
      loading,
      NewNeutroniumListingAmount,
      NewNeutroniumListingUnitPrice,
      NewSpaceDebrisListingAmount,
      NewSpaceDebrisListingUnitPrice,
      NeutroniumSellOrders: neutSellOrders,
      SpaceDebrisSellOrders: debrisSellOrders,
    } = this.state;

    return (
      <>
        <h3>Universal currency exchange of Now We Wait</h3>

        <small>All exchanges final. Intermediary currency: Credits</small>

        <Tab
          menu={{
            color: "grey",
            size: "mini",
            stackable: true,
            compact: true,
            inverted: true,
            attached: true,
            tabular: true,
            borderless: true,
          }}
          onTabChange={(event, data) => {
            this.loadSellOrders(data.panes[data.activeIndex].currencyType);
          }}
          panes={[
            {
              menuItem: "Neutronium",
              currencyType: "neutronium",
              render: () => (
                <Tab.Pane inverted key={"neut-sell-orders"}>
                  <Grid stackable textAlign="center">
                    <Grid.Row>&nbsp;</Grid.Row>
                    <Grid.Row>
                      <p>Create a new listing to sell Neutronium</p>
                    </Grid.Row>
                    <Grid.Row columns="equal">
                      <Grid.Column>
                        <Form.Input
                          onChange={this.handleChange}
                          disabled={loading}
                          icon="flask"
                          iconPosition="left"
                          name="NewNeutroniumListingAmount"
                          value={NewNeutroniumListingAmount}
                          type="numeric"
                          placeholder="Amount to sell"
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Form.Input
                          onChange={this.handleChange}
                          disabled={loading}
                          icon="credit card"
                          iconPosition="left"
                          name="NewNeutroniumListingUnitPrice"
                          value={NewNeutroniumListingUnitPrice}
                          type="numeric"
                          placeholder="Unit price"
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Button
                          disabled={loading}
                          compact
                          content="List"
                          onClick={() => {
                            this.addListing("neutronium");
                          }}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                  <Table inverted celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Amount</Table.HeaderCell>
                        <Table.HeaderCell>Unit price</Table.HeaderCell>
                        <Table.HeaderCell>Sold by</Table.HeaderCell>
                        <Table.HeaderCell>Actions</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {neutSellOrders.map((listing) => (
                        <SellOrderRow
                          key={`${listing.ID}-${listing.Amount}`}
                          listing={listing}
                          reloadListings={() => {
                            this.loadSellOrders("neutronium");
                          }}
                          ownListing={listing.PlayerName === this.context.player.Username}
                        />
                      ))}
                    </Table.Body>
                  </Table>
                </Tab.Pane>
              ),
            },
            {
              menuItem: "Space debris",
              currencyType: "space_debris",
              render: () => (
                <Tab.Pane inverted align={"left"} key={"debris-sell-orders"}>
                  <Grid stackable textAlign="center">
                    <Grid.Row>&nbsp;</Grid.Row>
                    <Grid.Row>
                      <p>Create a new listing to sell Space Debris</p>
                    </Grid.Row>
                    <Grid.Row columns="equal">
                      <Grid.Column>
                        <Form.Input
                          onChange={this.handleChange}
                          disabled={loading}
                          icon="recycle"
                          iconPosition="left"
                          name="NewSpaceDebrisListingAmount"
                          value={NewSpaceDebrisListingAmount}
                          type="numeric"
                          placeholder="Amount to sell"
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Form.Input
                          onChange={this.handleChange}
                          disabled={loading}
                          icon="credit card"
                          iconPosition="left"
                          name="NewSpaceDebrisListingUnitPrice"
                          value={NewSpaceDebrisListingUnitPrice}
                          type="numeric"
                          placeholder="Unit price"
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Button
                          disabled={loading}
                          compact
                          content="List"
                          onClick={() => {
                            this.addListing("space_debris");
                          }}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                  <Table inverted celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Amount</Table.HeaderCell>
                        <Table.HeaderCell>Unit price</Table.HeaderCell>
                        <Table.HeaderCell>Sold by</Table.HeaderCell>
                        <Table.HeaderCell>Actions</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {debrisSellOrders.map((listing) => (
                        <SellOrderRow
                          key={`${listing.ID}-${listing.Amount}`}
                          listing={listing}
                          reloadListings={() => {
                            this.loadSellOrders("space_debris");
                          }}
                          ownListing={listing.PlayerName === this.context.player.Username}
                        />
                      ))}
                    </Table.Body>
                  </Table>
                </Tab.Pane>
              ),
            },
          ]}
        />
      </>
    );
  }
}
