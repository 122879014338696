import React from "react";
import toast from "react-hot-toast";
import { AuthContext } from "components/utils/AuthorizationContext/AuthorizationContext";
import { Divider, Progress } from "semantic-ui-react";
import { GlobalHotKeys } from "react-hotkeys";

import { AnomalyPopup } from "components/pages/anomalies/AnomalyPopup";

export class ActionTracker extends React.PureComponent {
  static contextType = AuthContext;

  state = {
    loading: false,
  };

  refreshActions = (e) => {
    e.preventDefault();
    if (this.state.loading) return false;

    this.setState({ loading: true });
    this.context.socket.emit("RefreshActions");

    setTimeout(() => {
      this.setState({ loading: false });
    }, 3000);

    return false;
  };

  render = () => {
    const { loading } = this.state;
    const { player, loggedIn, latestBattleResult } = this.context;
    if (!loggedIn || !player) return null;

    let doubleTimer = false;
    if (latestBattleResult) {
      doubleTimer = latestBattleResult.ServerWideDoubleLeft;
      if (!doubleTimer || doubleTimer === "" || doubleTimer === "00:00:00") {
        doubleTimer = "";
      }

      if (latestBattleResult.PlayerResult.GainedAnomaly === true) toast.success("You detected a new anomaly!", { id: "anomaly-gained" });
      if (latestBattleResult.PlayerResult.GainedLevel === true) toast.success("You gained a level!", { id: "level-gained" });
    }

    const activeAnomaly = player.ActiveAnomaly;

    const keyMap = { GLOBAL_REFRESH: "r" };
    const handlers = { GLOBAL_REFRESH: this.refreshActions };

    return (
      <>
        <GlobalHotKeys keyMap={keyMap} allowChanges={true} handlers={handlers} />

        <Progress
          inverted
          size="small"
          onClick={this.refreshActions}
          className={loading ? "loading" : "pointer"}
          disabled={loading}
          value={player.ActionsRemaining}
          total={player.TotalMaximumActions}
        >
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          {player.ActionsRemaining} / {player.TotalMaximumActions} actions - <a href="#">Renew</a>
        </Progress>

        {activeAnomaly && (
          <>
            <Divider />

            <p>
              Exploring <AnomalyPopup anomaly={activeAnomaly} /> anomaly for {activeAnomaly.RemainingActions} more actions
            </p>
          </>
        )}

        {doubleTimer && (
          <>
            <Divider />

            <p>Double experience and credits: {doubleTimer} left</p>
          </>
        )}
      </>
    );
  };
}
