import { WithAuthService } from "services";

export const AnomaliesService = {
  GetUnexplored: async (handleError) => {
    let anomalies = false;
    await WithAuthService.doRequest({
      method: "GET",
      url: "anomalies/unexplored",
    })
      .then((response) => {
        if (response.result.status === 200) {
          anomalies = response.result.body;
          return;
        }

        handleError("Could not retrieve unexplored anomalies: " + response.result.body.Message);
      })
      .catch((error) => {
        handleError(error);
      });

    return anomalies;
  },

  Explore: async (anomalyID, handleError) => {
    let playerResult = false;
    await WithAuthService.doRequest({
      method: "PUT",
      url: `anomalies/explore/${anomalyID}`,
    })
      .then((response) => {
        if (response.result.status === 200) {
          playerResult = response.result.body;
          return;
        }

        handleError("Could not activate anomaly: " + response.result.body.Message);
      })
      .catch((error) => {
        handleError(error);
      });

    return playerResult;
  },

  Research: async (anomalyID, handleError) => {
    let result = false;
    await WithAuthService.doRequest({
      method: "DELETE",
      url: `anomalies/research/${anomalyID}`,
    })
      .then((response) => {
        if (response.result.status === 200) {
          result = response.result.body;
          return;
        }

        handleError("Could not research anomaly: " + response.result.body.Message);
      })
      .catch((error) => {
        handleError(error);
      });

    return result;
  },

  // TODO list anomaly on exchange
};
