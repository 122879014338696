import React from "react";
import { Divider, Dropdown, Icon, Menu } from "semantic-ui-react";
import { NavLink } from "react-router-dom";

import { PrivateComponent } from "components/utils/PrivateComponent";
import { AuthContext } from "components/utils/AuthorizationContext/AuthorizationContext";

export const HeaderMenu = () => {
  const context = React.useContext(AuthContext);

  return (
    <Menu size="tiny" fluid compact stackable inverted>
      {!context.loggedIn && <Menu.Item as={NavLink} icon="home" text="Home" to="/" />}

      <PrivateComponent>
        <Menu.Item as={NavLink} to="/battle">
          <Icon name="fighter jet" />
          Battle
        </Menu.Item>

        <Dropdown
          item
          trigger={
            <>
              <Icon name="box" /> Inventory
            </>
          }
        >
          <Dropdown.Menu>
            <Menu.Item as={NavLink} to="/equipment">
              <Icon name="microchip" />
              Modules
            </Menu.Item>

            <Menu.Item as={NavLink} to="/anomalies">
              <Icon name="map" />
              Anomalies
            </Menu.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown
          item
          trigger={
            <>
              <Icon name="btc" />
              Economy
            </>
          }
        >
          <Dropdown.Menu>
            <Menu.Item as={NavLink} to="/currency-exchange">
              <Icon name="exchange" />
              Currency exchange
            </Menu.Item>

            <Menu.Item as={NavLink} to="/shop">
              <Icon name="shopping cart" />
              Shop
            </Menu.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown
          item
          trigger={
            <>
              <Icon name="plus" /> Boosts
            </>
          }
        >
          <Dropdown.Menu>
            <Menu.Item as={NavLink} to="/homeplanet">
              <Icon name="home" />
              Homeplanet
            </Menu.Item>

            <Menu.Item as={NavLink} to="/boosts">
              <Icon name="plus" />
              Boosts
            </Menu.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown
          item
          trigger={
            <>
              <Icon name="industry" /> Industry
            </>
          }
        >
          <Dropdown.Menu>
            <Menu.Item disabled as={NavLink} to="/asteroid-belts">
              <Icon name="map" />
              Asteroid belts
            </Menu.Item>

            <Menu.Item disabled as={NavLink} to="/mining">
              <Icon name="rocket" />
              Mining
            </Menu.Item>

            <Menu.Item disabled as={NavLink} to="/smelting">
              <Icon name="gripfire" />
              Smelting
            </Menu.Item>

            <Menu.Item disabled as={NavLink} to="/manufacturing">
              <Icon name="industry" />
              Manufacturing
            </Menu.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown
          item
          trigger={
            <>
              <Icon name="user" /> Account
            </>
          }
        >
          <Dropdown.Menu>
            <Menu.Item disabled as={NavLink} to="/change-password">
              <Icon name="asterisk" />
              Change password
            </Menu.Item>

            <Menu.Item as={NavLink} to="/stats">
              <Icon name="chart pie" />
              Stats
            </Menu.Item>

            <Divider />

            <Menu.Item className="right" onClick={context.logout}>
              <Icon name="log out" />
              Logout
            </Menu.Item>
          </Dropdown.Menu>
        </Dropdown>
      </PrivateComponent>
    </Menu>
  );
};
