export const FormatThousands = (num, digits) => {
    let units = ["k", "M", "B", "T", "Q"],
        decimal;

    for (let i = units.length - 1; i >= 0; i--) {
        decimal = Math.pow(1000, i + 1);

        if (num <= -decimal || num >= decimal) {
            return +(num / decimal).toFixed(digits).toLocaleString("nl-NL") + units[i];
        }
    }

    return Number(num).toFixed(0);
};
