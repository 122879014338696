import React from "react";

import { Popup } from "semantic-ui-react";

import { DecorateAnomalyType } from "./Styles";
import { DisplaySingleAnomaly } from "./DisplaySingleAnomaly";

export const AnomalyPopup = ({ anomaly }) => {
  const { Id, Type } = anomaly;

  return (
    <Popup
      key={Id}
      basic
      hideOnScroll
      size="small"
      position="bottom center"
      on={["click"]}
      trigger={<span className="pointer">{DecorateAnomalyType(Type)}</span>}
    >
      <DisplaySingleAnomaly anomaly={anomaly} />
    </Popup>
  );
};
