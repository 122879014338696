import React from "react";
import { Button, Divider, List, Icon, Popup } from "semantic-ui-react";
import changeCase from "change-case";
import { WithAuthService } from "services";

export class ShipModuleLink extends React.PureComponent {
  state = {
    popupContent: <p>Loading...</p>,
  };

  determineIconName = (type = this.props.type) => {
    switch (type) {
      case "tracking":
        return "crosshairs";
      case "plasma-round-generator":
        return "fire";
      case "flares":
        return "sun";
      case "armor-plating":
        return "shield alternate";
      case "shield-generator":
        return "globe";
      case "tractor-beam":
        return "magnet";
      case "turret":
        return "bolt";
      case "crypto-miner":
        return "btc";
      case "threat-modeling":
        return "percent";
      case "portable-mass-compressor":
        return "cogs";
      default:
        return "";
    }
  };

  linkToChat = () => {
    let { quality, type, id } = this.props;

    // #chat-message-input-bar
    let chatInput = document.getElementById("chat-message-input-bar");
    if (chatInput.value.length < 165) {
      chatInput.value = chatInput.value + ` [[q:${quality}|t:${type}|id:${id}]] `;
      chatInput.focus();
    }
  };

  tooltipContent = async (id) => {
    let { level, quality, type } = this.props;
    let linkable = true;
    let url = `modules/${id}`;
    if (quality === "basic" && id === 0) {
      linkable = false;
      url = `${url}?quality=${quality}&type=${type}&level=${level}`;
    }

    let { success, result } = await WithAuthService.doRequest({
      method: "GET",
      url,
    });

    if (result.status === 429) {
      this.setState({
        popupContent: <p>Please slow down while looking at items</p>,
      });
      return;
    }

    if (!success) {
      this.setState({
        popupContent: <p>Error, could not retrieve item</p>,
      });
      return;
    }

    let {
      AccuracyIncreasePercentage: accuracy,
      BaseAccuracyIncreasePercentage: baseAccuracy,
      EvasionIncreasePercentage: evasion,
      BaseEvasionIncreasePercentage: baseEvasion,
      ArmorIncreasePercentage: armor,
      BaseArmorIncreasePercentage: baseArmor,
      DamageIncreasePercentage: damage,
      BaseDamageIncreasePercentage: baseDamage,
      HitpointsIncreasePercentage: hitpoints,
      BaseHitpointsIncreasePercentage: baseHitpoints,
      CriticalHitChanceIncrease: criticalHitChance,
      BaseCriticalHitChanceIncrease: baseCriticalHitChance,
      CreditsBoost: credits,
      BaseCreditsBoost: baseCredits,
      MultiHitBoost: multihit,
      BaseMultiHitBoost: baseMultihit,
      SpaceDebrisBoost: spaceDebris,
      BaseSpaceDebrisBoost: baseSpaceDebris,
      NeutroniumBoost: neutronium,
      BaseNeutroniumBoost: baseNeutronium,
      LevelRequirement: levelReq,
      OverclockingLevel: overclockedLevel,
      BaseLevel: baseLevel,
      AdditionalActions: additionalActions,
      FlatAccuracy: flatAccuracy,
      FlatEvasion: flatEvasion,
      FlatDamage: flatDamage,
      FlatArmor: flatArmor,
    } = result.body;

    let overClockRatio = (7.5 * overclockedLevel).toFixed(2);

    this.setState({
      popupContent: (
        <>
          <List>
            <List.Item align="center">
              <Icon size="large" name={this.determineIconName(type)} />
            </List.Item>
            <List.Item>
              Level requirement: <strong>{levelReq}</strong>
            </List.Item>
            <List.Item>
              Power base level: <strong>{baseLevel}</strong>
            </List.Item>
            <List.Item>
              Overclocked: <strong>{overClockRatio}%</strong>
            </List.Item>
            <List.Item>
              <Divider />
            </List.Item>

            {additionalActions > 0 && (
              <>
                <List.Item>
                  Additional maximum actions: <strong>{additionalActions}</strong>
                </List.Item>
                <List.Item>
                  <Divider />
                </List.Item>
              </>
            )}

            <List.Item>
              Quality: <strong>{changeCase.sentenceCase(quality)}</strong>
            </List.Item>
            <List.Item>
              Module type: <strong>{changeCase.sentenceCase(type)}</strong>
            </List.Item>

            {accuracy > 0 && (
              <>
                <List.Item>
                  Accuracy boost: <strong>{accuracy.toFixed(2)}%</strong>
                </List.Item>
                <List.Item>
                  Before overclocking: <strong>{baseAccuracy.toFixed(2)}%</strong>
                </List.Item>
              </>
            )}

            {evasion > 0 && (
              <>
                <List.Item>
                  Evasion boost: <strong>{evasion.toFixed(2)}%</strong>
                </List.Item>
                <List.Item>
                  Before overclocking: <strong>{baseEvasion.toFixed(2)}%</strong>
                </List.Item>
              </>
            )}

            {armor > 0 && (
              <>
                <List.Item>
                  Armor boost: <strong>{armor.toFixed(2)}%</strong>
                </List.Item>
                <List.Item>
                  Before overclocking: <strong>{baseArmor.toFixed(2)}%</strong>
                </List.Item>
              </>
            )}

            {damage > 0 && (
              <>
                <List.Item>
                  Damage boost: <strong>{damage.toFixed(2)}%</strong>
                </List.Item>
                <List.Item>
                  Before overclocking: <strong>{baseDamage.toFixed(2)}%</strong>
                </List.Item>
              </>
            )}

            {hitpoints > 0 && (
              <>
                <List.Item>
                  Hitpoints boost: <strong>{hitpoints.toFixed(2)}%</strong>
                </List.Item>
                <List.Item>
                  Before overclocking: <strong>{baseHitpoints.toFixed(2)}%</strong>
                </List.Item>
              </>
            )}

            {criticalHitChance > 0 && (
              <>
                <List.Item>
                  Added critical hit chance: <strong>{(criticalHitChance / 10).toFixed(1)}%</strong>
                </List.Item>
                <List.Item>
                  Before overclocking: <strong>{(baseCriticalHitChance / 10).toFixed(1)}%</strong>
                </List.Item>
              </>
            )}

            {multihit > 0 && (
              <>
                <List.Item>
                  Multi hit chance: <strong>{(multihit / 10).toFixed(1)}%</strong>
                </List.Item>
                <List.Item>
                  Before overclocking: <strong>{(baseMultihit / 10).toFixed(1)}%</strong>
                </List.Item>
              </>
            )}

            {credits > 0 && (
              <>
                <List.Item>
                  Credits boost: <strong>{credits.toFixed(2)}%</strong>
                </List.Item>
                <List.Item>
                  Before overclocking: <strong>{baseCredits.toFixed(2)}%</strong>
                </List.Item>
              </>
            )}

            {spaceDebris > 0 && (
              <>
                <List.Item>
                  Space debris quantity boost: <strong>{spaceDebris.toFixed(2)}%</strong>
                </List.Item>
                <List.Item>
                  Before overclocking: <strong>{baseSpaceDebris.toFixed(2)}%</strong>
                </List.Item>
              </>
            )}

            {neutronium > 0 && (
              <>
                <List.Item>
                  Neutronium quantity:{" "}
                  <strong>
                    +{neutronium.toFixed(2).toLocaleString("nl-NL")} ({Math.round(neutronium).toLocaleString("nl-NL")} effectively) each drop
                  </strong>
                </List.Item>
                <List.Item>
                  Before overclocking: <strong>+{baseNeutronium.toFixed(2).toLocaleString("nl-NL")}</strong>
                </List.Item>
              </>
            )}

            <List.Item>
              <Divider />
            </List.Item>

            <List.Item>
              <em>Module stat bonuses</em>
            </List.Item>
            <List.Item>
              <span>
                Accuracy: +<strong>{flatAccuracy.toLocaleString("nl-NL")}</strong>
              </span>
            </List.Item>
            <List.Item>
              <span>
                Evasion: +<strong>{flatEvasion.toLocaleString("nl-NL")}</strong>
              </span>
            </List.Item>
            <List.Item>
              <span>
                Damage: +<strong>{flatDamage.toLocaleString("nl-NL")}</strong>
              </span>
            </List.Item>
            <List.Item>
              <span>
                Armor: +<strong>{flatArmor.toLocaleString("nl-NL")}</strong>
              </span>
            </List.Item>
            <List.Item>
              <span>
                Total stats: +<strong>{(flatAccuracy + flatEvasion + flatDamage + flatArmor).toLocaleString("nl-NL")}</strong>
              </span>
            </List.Item>
          </List>

          {linkable && (
            <Button size="mini" onClick={this.linkToChat}>
              Link in chat
            </Button>
          )}
        </>
      ),
    });
  };

  render() {
    let { quality, id, type, inChat, showIcon } = this.props;

    let classNames = ["player-item", "rarity", quality];
    if (inChat === true) {
      classNames.push("chat-link");
    }

    return (
      <Popup
        key={id}
        basic
        hideOnScroll
        size="small"
        position="bottom center"
        on={["click"]}
        onOpen={() => {
          this.tooltipContent(id);
        }}
        trigger={
          <span onDoubleClick={this.linkToChat} className={classNames.join(" ")}>
            {showIcon && <Icon name={this.determineIconName()} />}
            {changeCase.sentenceCase(type)}
          </span>
        }
      >
        {this.state.popupContent}
      </Popup>
    );
  }
}
