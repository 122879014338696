import React from "react";
import { Button, Confirm } from "semantic-ui-react";
import { WithAuthService } from "services";
import { AuthContext } from "components/utils/AuthorizationContext/AuthorizationContext";
import { FormatThousands } from "components/utils/Helpers/Formatters";

export default class ConfirmOverclock extends React.PureComponent {
  static contextType = AuthContext;

  state = {
    open: false,
    module: this.props.module,
  };

  open = () =>
    this.setState({
      open: true,
    });

  close = () =>
    this.setState({
      open: false,
    });

  confirm = async () => {
    let { setError } = this.context;
    let moduleID = this.state.module.ID;

    WithAuthService.doRequest({
      method: "PUT",
      url: "players/overclock-item/" + moduleID,
    })
      .then((response) => {
        if (response.result.status !== 200 || !!response.result.body.Message) {
          setError("Could not overclock item: " + response.result.body.Message, 1500);
          return;
        }

        this.setState({
          module: response.result.body.Module,
        });

        this.context.updatePlayer(response.result.body.Player);
        this.props.afterConfirm && this.props.afterConfirm();
      })
      .catch((error) => {
        setError(error, 1500);
      });

    this.close();
  };

  cancel = () => {
    this.close();
  };

  render() {
    let module = this.state.module;

    return (
      <>
        <Button size="tiny" compact onClick={this.open}>
          Overclock
        </Button>
        <Confirm
          open={this.state.open}
          onCancel={this.cancel}
          onConfirm={this.confirm}
          content={`Overclocking increases the capabilities of a module by an additional 7.5% each time. This upgrade will cost you ${FormatThousands(
            module.NextOverclockCost,
            4
          )} Credits, are you sure?`}
        />
      </>
    );
  }
}
