import React from "react";
import { Button, Form, Segment } from "semantic-ui-react";

import { AuthContext } from "components/utils/AuthorizationContext/AuthorizationContext";

export class ChangePasswordPage extends React.Component {
  static contextType = AuthContext;

  state = {};

  render = () => {
    return (
      <Segment inverted>
        <p>Change your password (COMING SOON)</p>
        <Form>
          <Form.Field>
            <Form.Input size="small" label="Current password" type="password" placeholder="Your current password" />
          </Form.Field>

          <Form.Field>
            <Form.Input label="New password" type="password" placeholder="Your new password" />
          </Form.Field>

          <Form.Field>
            <Form.Input label="Confirm new password" type="password" placeholder="Confirm your new password" />
          </Form.Field>

          <Button size="small" content="Confirm" disabled />
        </Form>
      </Segment>
    );
  };
}
