import { BaseService } from "./base.service";

const redirectToLogin = () => {
  if (window.location.pathname !== "/login") {
    window.location = "/login";
  }
};

// API services with auth logic and refresh token integration
export const WithAuthService = BaseService.extend({
  hooks: {
    // add client token
    before({ payload, next }) {
      const token = localStorage.getItem("nww.token");

      payload.headers = {
        ...payload.headers,
        Authorization: `Bearer ${token}`,
      };

      next(payload);
    },

    // if it's failed, try to refresh token
    fail({ payload, result, retry, next }) {
      if (result.status !== 401 && result.status !== 403) {
        // eslint-disable-next-line
        console.log("Not a 401 or 403, something else");
        return next(result);
      }

      const refreshToken = localStorage.getItem("nww.refresh_token");
      if (!refreshToken) {
        redirectToLogin();
        return;
      }

      BaseService.doRequest({
        url: "auth/refresh",
        method: "PUT",
        body: {
          refreshToken,
        },
      })
        .then((response) => {
          if (response.result.status !== 200) {
            redirectToLogin();
            return;
          }

          const { Token, Expires, RefreshToken } = response.result.body;

          localStorage.setItem("nww.token", Token);
          localStorage.setItem("nww.refresh_token", RefreshToken);
          localStorage.setItem("nww.expires", Expires);

          payload.headers.Authorization = `Bearer ${Token}`;

          return retry(payload);
        })
        .catch((error) => {
          localStorage.removeItem("nww.token");
          localStorage.removeItem("nww.expires");
          localStorage.removeItem("nww.refresh_token");
          redirectToLogin();
          return;
        });
    },
  },
});
