import React from "react";
import { Button } from "semantic-ui-react";
import { Link } from "react-router-dom";

import { AuthContext } from "components/utils/AuthorizationContext/AuthorizationContext";

export class HomePage extends React.Component {
  static contextType = AuthContext;

  render() {
    let loggedIn = this.context.loggedIn;

    return (
      <div>
        <p>
          Welcome to Now We Wait, a permanent browser based space-themed progression game where you get stronger by killing enemies and spending your
          hard-earned credits on upgrades!
        </p>

        <p>
          <strong>PROUDLY IN ALPHA!</strong>
        </p>

        {!loggedIn && (
          <Button as={Link} to="/register">
            Register
          </Button>
        )}

        {!loggedIn && (
          <Button as={Link} to="/login">
            Log in
          </Button>
        )}
      </div>
    );
  }
}
