import React from "react";
import { Link, Redirect } from "react-router-dom";
import { Button, Divider, Form, Header, Message } from "semantic-ui-react";
import { BaseService } from "../../services";

export class RegisterPage extends React.Component {
  state = {
    username: "",
    password: "",
    passwordConfirm: "",
    email: "",
    emailConfirm: "",
    error: "",
    success: false,
    submitted: false,
    loading: false,
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({
      submitted: true,
      error: "",
    });

    const { username, password, passwordConfirm, email, emailConfirm } = this.state;

    // stop here if form is invalid, we require all fields to be filled
    if (!username || !password || !passwordConfirm || !email || !emailConfirm) {
      return;
    }

    this.setState({
      loading: true,
    });

    if (password !== passwordConfirm) {
      this.setState({
        loading: false,
        success: false,
        error: "You did not fill in matching passwords",
      });
      return;
    }

    if (email !== emailConfirm) {
      this.setState({
        loading: false,
        success: false,
        error: "You did not fill in matching email addresses",
      });
      return;
    }

    BaseService.doRequest({
      url: "auth/register",
      method: "POST",
      body: { username, password, passwordConfirm, email, emailConfirm },
    }).then((response) => {
      if (response.result.status !== 200) {
        this.setState({
          loading: false,
          success: false,
          error: response.result.body.Message,
        });
        return;
      }

      this.setState({
        loading: false,
        success: true,
        error: "",
      });
    });
  };

  render() {
    const { username, password, passwordConfirm, email, emailConfirm, submitted, loading, error, success } = this.state;

    if (this.context.loggedIn) {
      return <Redirect to="/battle" />;
    }

    if (success) {
      return (
        <div>
          <Message positive>
            <Header>Registered successfully! Please check your email (and possibly the spam folder) for instructions on activating your account!</Header>
          </Message>

          <Link to="/login">
            <Button>Take me to the login page</Button>
          </Link>
        </div>
      );
    }

    return (
      <div className="registration-form">
        <h1>Pilot registration form</h1>

        <Link to="/login">
          <Button>Login instead</Button>
        </Link>

        <Divider />

        <Form name="form" onSubmit={this.handleSubmit}>
          <Form.Input
            icon="user"
            iconPosition="left"
            placeholder="Username"
            autoComplete="off"
            value={username}
            name="username"
            onChange={this.handleChange}
            className={"form-group" + (submitted && !username ? " error" : "")}
          />
          {submitted && !username && <p>Username is required</p>}

          <Form.Input
            icon="lock"
            iconPosition="left"
            placeholder="Password"
            type="password"
            name="password"
            value={password}
            onChange={this.handleChange}
            className={"form-group" + (submitted && !password ? " error" : "")}
          />
          {submitted && !password && <p>Password is required</p>}

          <Form.Input
            icon="lock"
            iconPosition="left"
            placeholder="Confirm password"
            type="password"
            name="passwordConfirm"
            value={passwordConfirm}
            onChange={this.handleChange}
            className={"form-group" + (submitted && !passwordConfirm ? " error" : "")}
          />
          {submitted && !passwordConfirm && <p>Password confirmation is required</p>}

          <Form.Input
            icon="mail"
            iconPosition="left"
            placeholder="Email address"
            type="email"
            name="email"
            value={email}
            onChange={this.handleChange}
            className={"form-group" + (submitted && !email ? " error" : "")}
          />
          {submitted && !email && <p>Email address is required</p>}

          <Form.Input
            icon="mail"
            iconPosition="left"
            placeholder="Email address confirmation"
            type="email"
            name="emailConfirm"
            value={emailConfirm}
            onChange={this.handleChange}
            className={"form-group" + (submitted && !emailConfirm ? " error" : "")}
          />
          {submitted && !emailConfirm && <p>Email address confirmation is required</p>}

          <Button disabled={loading} className={loading ? "loading" : ""}>
            Register
          </Button>

          {error && (
            <Message negative>
              <Header>{error}</Header>
            </Message>
          )}
        </Form>
      </div>
    );
  }
}
