import { Config } from "components/utils/Config";
import { EventEmitter } from "events";
import ReconnectingWebSocket from "reconnecting-websocket";

export class GlobalSocket {
    connect() {
        let { host, protocol } = Config.BackendDomain();
        let socketProtocol = protocol === "https://" ? "wss://" : "ws://";

        // url provider that gets a new token whenever it's available before reconnecting
        const urlProvider = () => {
            let authorizationToken = localStorage.getItem("nww.token");
            return `${socketProtocol}${host}/api/game/server/connect?authorization=${authorizationToken}`;
        };

        const options = {
            reconnectionDelayGrowFactor: 2,
            minUptime: 500,
            connectionTimeout: 2500,
            maxRetries: 15,
            debug: false,
            minReconnectionDelay: 50,
            maxReconnectionDelay: 10000,
        };

        let socket = new ReconnectingWebSocket(urlProvider, [], options);

        this.ee = new EventEmitter();
        this.on = this.on.bind(this);
        this.off = this.off.bind(this);
        this.emit = this.emit.bind(this);
        this.message = this.message.bind(this);

        socket.addEventListener("message", (data) => {
            this.message(data);
        });

        this.socket = socket;

        return this;
    }

    emit(name, data) {
        const message = JSON.stringify({ Name: name, Data: data });
        if (this.socket) {
            this.socket.send(message);
        }
    }

    message(e) {
        try {
            const msgData = JSON.parse(e.data);
            this.ee.emit(msgData.Name, msgData.Data);
        } catch (err) {
            let error = {
                message: err,
            };

            this.ee.emit(error.message);
        }
    }

    on(messageName, handler) {
        if (this.ee) {
            this.ee.on(messageName, handler);
        }
    }

    off(messageName, handler) {
        if (this.ee) {
            this.ee.removeListener(messageName, handler);
        }
    }

    disconnect() {
        if (!this.socket) {
            return false;
        }

        this.ee.emit("Disconnected");
        this.socket.close();
    }
}
