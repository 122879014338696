import React from "react";
import { Confirm, Button } from "semantic-ui-react";
import { AuthContext } from "components/utils/AuthorizationContext/AuthorizationContext";
import { WithAuthService } from "services";

export default class ConfirmDisassemble extends React.PureComponent {
  static contextType = AuthContext;

  state = {
    open: false,
  };

  open = () => this.setState({ open: true });

  close = () => this.setState({ open: false });

  confirm = async () => {
    const { updatePlayer, setError } = this.context;
    const moduleID = this.props.module.ID;

    await WithAuthService.doRequest({
      method: "DELETE",
      url: `players/disassemble-item/${moduleID}`,
    })
      .then((response) => {
        if (response.result.status === 200) {
          updatePlayer(response.result.body.Player);
          this.props.afterConfirm && this.props.afterConfirm();
          return;
        }

        setError("Could not disassemble item: " + response.result.body.Message, 1500);
      })
      .catch((error) => {
        setError(error, 1500);
      });

    this.close();
  };

  render() {
    let reward = "nothing";

    // eslint-disable-next-line default-case
    switch (this.props.module.Quality) {
      case "worn-out":
        reward = "2500 Space Debris";
        break;

      case "average":
        reward = "4500 Space Debris";
        break;

      case "military-grade":
        reward = "7000 Space Debris";
        break;

      case "neutronium-powered":
        reward = "7000 Space Debris and 10 Neutronium";
    }

    return (
      <div>
        <Button size="tiny" negative compact onClick={this.open}>
          Disassemble
        </Button>
        <Confirm
          open={this.state.open}
          onCancel={this.close}
          onConfirm={this.confirm}
          content={"Disassembling this item would give you " + reward + ". Are you sure?"}
        />
      </div>
    );
  }
}
