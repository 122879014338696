import React from "react";
import { Confirm, Button } from "semantic-ui-react";
import { AuthContext } from "components/utils/AuthorizationContext/AuthorizationContext";
import { WithAuthService } from "services";

export default class ConfirmMassDisassemble extends React.PureComponent {
  static contextType = AuthContext;

  state = {
    open: false,
    dryRunResult: {
      DisassembleCount: 0,
      SpaceDebrisGained: 0,
      NeutroniumGained: 0,
    },
  };

  open = () => {
    let { setError } = this.context;

    WithAuthService.doRequest({
      method: "DELETE",
      url: "players/mass-disassemble",
      body: {
        MaximumQuality: this.props.maximumQuality,
        DryRun: true,
      },
    })
      .then((response) => {
        if (response.result.status === 200) {
          this.setState({
            dryRunResult: response.result.body,
            open: true,
          });
        }

        setError(response.result.body.Message, 2000);
      })
      .catch((error) => {
        setError(error, 2000);
      });
  };

  close = () =>
    this.setState({
      open: false,
    });

  confirm = () => {
    let { updatePlayer, setError } = this.context;

    WithAuthService.doRequest({
      method: "DELETE",
      url: "players/mass-disassemble",
      body: {
        MaximumQuality: this.props.maximumQuality,
        DryRun: false,
      },
    })
      .then((response) => {
        if (response.result.status === 200) {
          updatePlayer(response.result.body.Player);
          this.props.afterConfirm();
          return;
        }

        setError("Could not perform mass disassembly of unused modules: " + response.result.body.Message, 1500);
      })
      .catch((error) => {
        setError(error, 1500);
      });

    this.close();
  };

  cancel = () => {
    this.close();
  };

  render() {
    let result = this.state.dryRunResult;
    let content = (
      <div className="content">
        <p>
          This will result in {result.DisassembleCount} modules being disassembled, giving you {result.NeutroniumGained} Neutronium and{" "}
          {result.SpaceDebrisGained} Space Debris
        </p>
      </div>
    );

    return (
      <>
        <Button disabled={!this.props.maximumQuality} size={this.props.size} color="red" negative onClick={this.open}>
          Mass disassemble
        </Button>
        <Confirm open={this.state.open} onCancel={this.cancel} onConfirm={this.confirm} content={content} />
      </>
    );
  }
}
