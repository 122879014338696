import React from "react";
import { Table, Label } from "semantic-ui-react";

import { BaseService } from "services";
import { FormatThousands } from "components/utils/Helpers/Formatters";
import { UserLink } from "components/ui-elements";
import { AuthContext } from "components/utils/AuthorizationContext/AuthorizationContext";

export class HighscoresPage extends React.Component {
  static contextType = AuthContext;

  state = {
    pilots: [],
  };

  componentDidMount() {
    BaseService.doRequest({
      method: "GET",
      url: "highscores/top",
    })
      .then((response) => {
        if (response.result.status !== 200 || response.result.body.Message) {
          this.context.setError(response.result.body.Message, 1000);
          return;
        }

        this.setState({
          pilots: response.result.body.Pilots,
        });
      })
      .catch((error) => {
        this.context.setError(error, 1000);
      });
  }

  render() {
    let pilots = this.state.pilots;

    return (
      <div className="highscores">
        <Table inverted celled striped compact="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan={5}>Top 250 pilots in the universe</Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Rank</Table.HeaderCell>
              <Table.HeaderCell>Pilot</Table.HeaderCell>
              <Table.HeaderCell>Level</Table.HeaderCell>
              <Table.HeaderCell>Total actions done</Table.HeaderCell>
              <Table.HeaderCell>Total stats gained</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {pilots.map((pilot, index) => {
              let rank = pilot.Rank;
              if (rank === 1 || rank === 2 || rank === 3) {
                rank = <Label ribbon>{rank}</Label>;
              }

              return (
                <Table.Row key={index}>
                  <Table.Cell>{rank}</Table.Cell>
                  <Table.Cell>
                    <UserLink brackets={false} username={pilot.Username} />
                  </Table.Cell>
                  <Table.Cell>{pilot.Level}</Table.Cell>
                  <Table.Cell>{FormatThousands(pilot.TotalActionsDone, 3)}</Table.Cell>
                  <Table.Cell>{FormatThousands(pilot.TotalStats, 3)}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </div>
    );
  }
}
