import React from "react";

import { PrintModifiers, DecorateAnomalyType } from "./Styles";

export const DisplaySingleAnomaly = ({ anomaly }) => {
  const { Type, RemainingActions } = anomaly;

  return (
    <>
      <p>
        Type: {DecorateAnomalyType(Type)}
        <br />
        <br />
        Remaining ticks until collapse: {RemainingActions}
        <br />
        <br />
        Special properties:
      </p>
      {PrintModifiers(anomaly)}
    </>
  );
};
