import React from "react";
import { Button } from "semantic-ui-react";

import ConfirmOverclock from "components/pages/equipment/ConfirmOverclock";
import { ShipModuleLink } from "./ShipModuleLink";

export class ShipModule extends React.PureComponent {
  render() {
    let { OverclockingLevel: overclockedLevel, LevelRequirement: level, Equipped: installed, Quality: quality, Type: type, ID: id } = this.props.module;

    let classNames = [];
    if (installed) {
      classNames.push("equipped-item");
    }

    return (
      <div className={classNames.join(" ")}>
        <p>
          <ShipModuleLink type={type} quality={quality} id={id} level={level} showIcon={true} />
        </p>
        {installed && (
          <div>
            <p>
              Level {level}, overclocked {(overclockedLevel * 7.5).toFixed(1)}%
            </p>
            <p>
              <ConfirmOverclock module={this.props.module} afterConfirm={this.props.onOverclock} />

              <Button size="tiny" compact onClick={this.props.onUnequip}>
                Uninstall
              </Button>
            </p>
          </div>
        )}
      </div>
    );
  }
}
