export class Config {
  static BackendDomain() {
    // check for development first, as that is a more complete URL to check for
    if (window.location.toString().includes("test.now-we-wait.nl")) {
      return {
        host: "backend-test.now-we-wait.nl",
        protocol: "https://",
      };
    }

    if (window.location.toString().includes("test.now-we-wait.com")) {
      return {
        host: "backend-test.now-we-wait.com",
        protocol: "https://",
      };
    }

    // check for production
    if (window.location.toString().includes("now-we-wait.nl")) {
      return {
        host: "backend.now-we-wait.nl",
        protocol: "https://",
      };
    }

    // check for production
    if (window.location.toString().includes("now-we-wait.com")) {
      return {
        host: "backend.now-we-wait.com",
        protocol: "https://",
      };
    }

    // lastly, provide a safe, sane default, by falling back to localhost
    return {
      host: "127.0.0.1:5000",
      protocol: "http://",
    };
  }
}
