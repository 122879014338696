import React from "react";
import { Dropdown, Grid, Table, Button, Segment, Input } from "semantic-ui-react";
import { AuthContext } from "components/utils/AuthorizationContext/AuthorizationContext";
import { ShipModule } from "components/ui-elements";
import { WithAuthService } from "services";

import ConfirmMassDisassemble from "./ConfirmMassDisassemble";
import ConfirmMassOverclock from "./ConfirmMassOverclock";
import ConfirmDisassemble from "./ConfirmDisassemble";
import ConfirmOverclock from "./ConfirmOverclock";

import "styling/equipment.css";

export class EquipmentPage extends React.Component {
  static contextType = AuthContext;

  state = {
    filter: "",
    massDisassembleThreshold: "",
    massOverclockLevel: "",
    inventory: [],
    equippedModules: [],
  };

  overclockOptions = [];

  componentDidMount() {
    this.loadInventory();
    this.loadEquipment();
    this.overclockOptions = this.generateOverclockOptions();
  }

  loadEquipment = () => {
    WithAuthService.doRequest({
      url: "players/me",
      method: "GET",
    }).then((response) => {
      if (response.result.status === 200) {
        this.setState({ equippedModules: response.result.body.EquippedModules });
      }
    });
  };

  loadInventory = () => {
    WithAuthService.doRequest({
      method: "GET",
      url: "players/inventory",
    })
      .then((response) => {
        this.setState({ inventory: response.result.body });
      })
      .catch((error) => {
        this.context.setError(error, 1500);
      });
  };

  unequipModule = async (module) => {
    WithAuthService.doRequest({
      method: "PUT",
      url: `players/unequip-item/${module.ID}`,
    })
      .then((response) => {
        if (response.result.status === 200) {
          this.context.updatePlayer(response.result.body);
          this.setState({ equippedModules: response.result.body.EquippedModules });
          this.loadInventory();
          return;
        }

        this.context.setError("Could not unequip item: " + response.result.body.Message, 1500);
      })
      .catch((error) => {
        this.context.setError(error, 1500);
      });
  };

  equipModule = async (module) => {
    WithAuthService.doRequest({
      method: "PUT",
      url: `players/equip-item/${module.ID}`,
    })
      .then((response) => {
        if (response.result.status === 200) {
          this.context.updatePlayer(response.result.body);
          this.setState({ equippedModules: response.result.body.EquippedModules });
          this.loadInventory();
          return;
        }

        this.context.setError("Could not equip item: " + response.result.body.Message, 1500);
      })
      .catch((error) => {
        this.context.setError(error, 1500);
      });
  };

  changeMassDisassembleThreshold = (e, { value }) => {
    e.preventDefault();
    this.setState({ massDisassembleThreshold: value });
  };

  changeMassOverclockLevel = (e, { value }) => {
    e.preventDefault();
    this.setState({ massOverclockLevel: value });
  };

  changeFilter = (e, { value }) => {
    this.setState({ filter: value });
  };

  generateOverclockOptions = () => {
    let options = [];
    for (let i = 1; i <= 41; i++) {
      options.push({
        value: i,
        text: `Tier ${i} - ${i * 7.5}%`,
      });
    }
    return options;
  };

  filteredModules = () => {
    let { filter, inventory } = this.state;
    let { player } = this.context;
    let unlockedSlots = Math.floor(player.Level / 100).toFixed(0);

    return inventory
      .filter((module) => {
        if (!filter) return module;

        // TODO improve the filter (level ranges? overclockedness?)
        if (module.Quality.toLowerCase().includes(filter.toLowerCase())) return module;
        if (module.Type.toLocaleLowerCase().includes(filter.toLowerCase())) return module;
        return null;
      })
      .map((module) => {
        let isInstallable = player.Level >= module.LevelRequirement && unlockedSlots > player.EquippedModules.length;

        return (
          <Table.Row key={module.ID}>
            <Table.Cell>
              <ShipModule module={module} />
            </Table.Cell>
            <Table.Cell>
              {module.BaseLevel} ({module.LevelRequirement})
            </Table.Cell>
            <Table.Cell>+{module.OverclockingLevel * 7.5}%</Table.Cell>
            <Table.Cell>
              <ConfirmOverclock module={module} afterConfirm={this.loadInventory} />
            </Table.Cell>
            <Table.Cell>
              <Button disabled={!isInstallable} size="tiny" compact onClick={() => this.equipModule(module)}>
                Install
              </Button>
            </Table.Cell>
            <Table.Cell>
              <ConfirmDisassemble afterConfirm={this.loadInventory} module={module} />
            </Table.Cell>
          </Table.Row>
        );
      });
  };

  render() {
    let { player } = this.context;
    let { equippedModules, filter } = this.state;

    let unlockedSlots = Math.floor(player.Level / 100).toFixed(0);
    if (unlockedSlots > 10) {
      unlockedSlots = 10;
    }

    if (!player) {
      return <p>Loading...</p>;
    }

    return (
      <>
        <h3>Ship module management</h3>

        {player.Level < 1005 && (
          <p>
            You can currently install {unlockedSlots} modules <br />
            An additional slot unlocks every 100 levels, up to level 1000
          </p>
        )}

        <Segment inverted>
          <Grid columns={2} centered stackable>
            {equippedModules.map((module) => {
              return (
                <Grid.Column key={`${module.ID}-${module.UpdatedAt}`} textAlign="center">
                  <ShipModule module={module} onUnequip={() => this.unequipModule(module)} onOverclock={this.loadEquipment} />
                </Grid.Column>
              );
            })}
          </Grid>
        </Segment>

        <Segment inverted>
          <p>Mass overclock all equipped modules to the following tier:</p>
          <Dropdown
            selection
            value={this.state.massOverclockLevel}
            onChange={this.changeMassOverclockLevel}
            placeholder="Select tier"
            options={this.overclockOptions}
          />

          <ConfirmMassOverclock selectedTier={this.state.massOverclockLevel} afterConfirm={this.loadEquipment} />
        </Segment>

        <Segment inverted>
          <p>Disassemble all unequipped, non-overclocked, modules of a quality up to and including</p>

          <Dropdown
            selection
            value={this.state.massDisassembleThreshold}
            onChange={this.changeMassDisassembleThreshold}
            placeholder="Select maximum quality"
            options={[
              { value: "basic", text: "Basic" },
              { value: "worn-out", text: "Worn-out" },
              { value: "average", text: "Average" },
              { value: "military-grade", text: "Military grade" },
              { value: "neutronium-powered", text: "Neutronium powered" },
            ]}
          />

          <ConfirmMassDisassemble afterConfirm={this.loadInventory} maximumQuality={this.state.massDisassembleThreshold} />
        </Segment>

        <div className="inventory-table-wrapper">
          <Table inverted celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan={1}>Uninstalled modules</Table.HeaderCell>
                <Table.HeaderCell colSpan={5}>
                  <Input placeholder={"Filter inventory"} value={filter} onChange={this.changeFilter} />
                </Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell width={8}>Module</Table.HeaderCell>
                <Table.HeaderCell width={2}>Level base (requirement)</Table.HeaderCell>
                <Table.HeaderCell width={1}>Overclocked</Table.HeaderCell>
                <Table.HeaderCell width={5} colSpan={3}>
                  Actions
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{this.filteredModules()}</Table.Body>
          </Table>
        </div>
      </>
    );
  }
}
