import { ShipModuleLink, UserLink } from "components/ui-elements";
import Moment from "moment";
import React from "react";
import styled from "styled-components";
import processString from "react-process-string";

// [[q:worn-out|t:flares|id:59]]
const itemRegex = /\[\[q:([a-z-]*)\|t:([a-z-]*)\|id:([0-9]*)]]/g;
const urlRegex = /(http|https):\/\/(\S+)\.([a-zA-Z]{2,}?)(.*?)( |,|$|\.)/g;
const domainRegex = /(\S+)\.([a-zA-Z]{2,}?)(.*?)( |,|$|\.)/g;

// TODO
// [[anom|q:unstable|id:555]]
// const anomalyRegex = /\[\[anom\|q:(unstable|dissipating|stable|nexus)\|id:([0-9]*)]]/g;

const regexConfig = [
  {
    regex: urlRegex,
    fn: (key, result) => (
      <span key={key}>
        <a target="_blank" rel="noopener noreferrer" href={`${result[1]}://${result[2]}.${result[3]}${result[4]}`}>
          {result[2]}.{result[3]}
          {result[4]}
        </a>
        {result[5]}
      </span>
    ),
  },
  {
    regex: domainRegex,
    fn: (key, result) => (
      <span key={key}>
        <a target="_blank" rel="noopener noreferrer" href={`https://${result[1]}.${result[2]}${result[3]}`}>
          {result[1]}.{result[2]}
          {result[3]}
        </a>
        {result[4]}
      </span>
    ),
  },
  {
    regex: itemRegex,
    fn: (key, match) => <ShipModuleLink key={key} showIcon={true} inChat={true} quality={match[1]} type={match[2]} id={match[3]} />,
  },

  // TODO
  // {
  //   regex: anomalyRegex,
  //   fn: (key, match) => <AnomalyPopupLink key={key} inChat={true} quality={match[2]} id={match[3]} />,
  // },
];

export class ChatMessage extends React.PureComponent {
  renderText = () => {
    let content = this.props.message.Content;
    return processString(regexConfig)(content);
  };

  render() {
    let { message } = this.props;

    let prefix = "";
    if (message.Timestamp) {
      let moment = Moment(message.Timestamp);
      let date = moment.format("Do of MMMM, YYYY");
      let time = moment.format("HH:mm:ss");

      prefix = <Timestamp title={date}>[{time}]</Timestamp>;
    }

    if (message.Channel === "whispers") {
      if (message.FromPlayerID === this.props.currentUserID) {
        prefix = (
          <>
            {prefix} to {message.ToUsername}:
          </>
        );
      } else {
        prefix = (
          <>
            {prefix} from {message.FromUsername}:
          </>
        );
      }
    } else if (message.IsError === true || message.Channel === "errors") {
      prefix = <>{prefix} ERROR:</>;
    } else {
      if (message.FromUsername) {
        return (
          <Message>
            {prefix} <UserLink brackets={true} username={message.FromUsername} /> {this.renderText()}
          </Message>
        );
      }
    }

    return (
      <Message>
        {prefix} {this.renderText()}
      </Message>
    );
  }
}

const Message = styled.p`
  line-height: 14px;
  font-size: 14px;
`;

const Timestamp = styled.span`
  font-family: monospace, monospace;
  line-height: 14px;
  font-size: 14px;
`;
