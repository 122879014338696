import React from "react";
import { Form, Button, Message, Header } from "semantic-ui-react";
import { Redirect, Link } from "react-router-dom";

import { AuthContext } from "components/utils/AuthorizationContext/AuthorizationContext";

export class LoginPage extends React.Component {
  static contextType = AuthContext;

  state = {
    username: "",
    password: "",
    submitted: false,
    loading: false,
    error: false,
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({ submitted: true });
    const { username, password } = this.state;

    // stop here if form is invalid
    if (!username || !password) {
      return;
    }

    this.setState({
      loading: true,
    });

    this.context.login(username, password);

    this.setState({
      loading: false,
    });
  };

  componentDidMount() {
    let from = "";
    if (this.props.location && this.props.location.state && this.props.location.state.from) {
      from = this.props.location.state.from.pathname;
    }

    if (this.props.location.pathname === "/login" && from === "") {
      this.setState({
        loading: false,
      });
      return;
    }

    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 500);
  }

  render() {
    const { username, password, submitted, loading, error } = this.state;

    if (this.state.loading === true) {
      return <p>Loading...</p>;
    }

    if (this.context.loggedIn) {
      let from = "/battle";
      if (this.props.location && this.props.location.state && this.props.location.state.from) {
        from = this.props.location.state.from.pathname;
      }

      return <Redirect to={from} />;
    }

    return (
      <div className="login-form">
        <Form name="form" onSubmit={this.handleSubmit}>
          <Form.Input
            icon="user"
            iconPosition="left"
            placeholder="Username"
            autoComplete="off"
            value={username}
            name="username"
            onChange={this.handleChange}
            className={"form-group" + (submitted && !username ? " error" : "")}
          />
          {submitted && !username && <p>Username is required</p>}

          <Form.Input
            icon="lock"
            iconPosition="left"
            placeholder="Password"
            type="password"
            name="password"
            value={password}
            onChange={this.handleChange}
            className={"form-group" + (submitted && !password ? " error" : "")}
          />
          {submitted && !password && <p>Password is required</p>}

          <Button disabled={loading} className={loading ? "loading" : ""}>
            Log in
          </Button>

          <Button to="/register" as={Link} disabled={loading}>
            Register instead
          </Button>

          {error && (
            <Message negative>
              <Header>Login failed, please check your username and password, or the server might be down...</Header>
            </Message>
          )}
        </Form>
      </div>
    );
  }
}
