import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, withRouter } from "react-router-dom";
import { App } from "components/app";

import "styling/styles.global.css";
import "semantic/dist/semantic.min.css";

const AppWithRouting = withRouter(App);

const TrackedApp = () => (
  <BrowserRouter>
    <AppWithRouting />
  </BrowserRouter>
);

ReactDOM.render(<TrackedApp />, document.getElementById("root"));
