import React from "react";
import { Icon, Menu } from "semantic-ui-react";
import { NavLink } from "react-router-dom";

import { PrivateComponent } from "components/utils/PrivateComponent";
import { AuthContext } from "components/utils/AuthorizationContext/AuthorizationContext";

export const FooterMenu = () => {
  const context = React.useContext(AuthContext);

  const [onlinePlayerCount, setOnlinePlayerCount] = React.useState("...");

  const playerCountListener = (data) => {
    setOnlinePlayerCount(data.Count);
  };

  React.useEffect(() => {
    context?.socket?.on("OnlinePlayerUpdate", playerCountListener);

    return function cleanup() {
      context?.socket?.off("OnlinePlayerUpdate", playerCountListener);
    };
  }, [context.socket]);

  return (
    <Menu size="tiny" compact fluid stackable inverted>
      <Menu.Item as={NavLink} to="/news">
        <Icon name="newspaper" />
        News
      </Menu.Item>

      <Menu.Item as={NavLink} to="/highscores">
        <Icon name="list ol" />
        Highscores
      </Menu.Item>

      <Menu.Item as={NavLink} to="/faq">
        <Icon name="question circle outline" />
        FAQ
      </Menu.Item>

      <Menu.Item href="https://discord.gg/3sP3pfp" target="_blank">
        <Icon name="discord" />
        Discord
      </Menu.Item>

      <PrivateComponent>
        <Menu.Item as={NavLink} to="/online-players" className="right">
          <Icon name="history" />
          Online players: {onlinePlayerCount}
        </Menu.Item>
      </PrivateComponent>
    </Menu>
  );
};
