import React from "react";
import { AuthContext } from "components/utils/AuthorizationContext/AuthorizationContext";
import { Grid, Icon } from "semantic-ui-react";
import { FormatThousands } from "../utils/Helpers/Formatters";

export class StatTracker extends React.Component {
  static contextType = AuthContext;

  render = () => {
    const { player, loggedIn } = this.context;
    if (!loggedIn) {
      return null;
    }

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <p data-tooltip={`${Number(player.EffectiveDamage.toFixed(0)).toLocaleString("nl-NL")} Damage`}>
              <Icon name="fire" />
              <strong>{FormatThousands(player.EffectiveDamage, 2)}</strong>
            </p>
          </Grid.Column>

          <Grid.Column width={8}>
            <p data-tooltip={`${Number(player.ModifiedArmor.toFixed(0)).toLocaleString("nl-NL")} Armor`}>
              <Icon name="shield alternate" />
              <strong>{FormatThousands(player.ModifiedArmor, 2)}</strong>
            </p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <p data-tooltip={`${Number(player.ModifiedAccuracy.toFixed(0)).toLocaleString("nl-NL")} Accuracy`}>
              <Icon name="crosshairs" />
              <strong>{FormatThousands(player.ModifiedAccuracy, 2)}</strong>
            </p>
          </Grid.Column>

          <Grid.Column width={8}>
            <p data-tooltip={`${Number(player.ModifiedEvasion.toFixed(0)).toLocaleString("nl-NL")} Evasion`}>
              <Icon name="sun" />
              <strong>{FormatThousands(player.ModifiedEvasion, 2)}</strong>
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };
}
