import React from "react";
import toast from "react-hot-toast";
import DataTable from "react-data-table-component";
import styled from "styled-components";
import { Grid, Button, Segment, Checkbox } from "semantic-ui-react";

import { AuthContext } from "components/utils/AuthorizationContext/AuthorizationContext";
import { AnomaliesService } from "services";

import { AnomalyPopup } from "./AnomalyPopup";
import { DisplaySingleAnomaly } from "./DisplaySingleAnomaly";
import { ColorizeModifier } from "./Styles";

import "styling/equipment.css";

const anomalyTypeSort = (rowA, rowB) => {
  const typeValue = {
    nexus: 4,
    stable: 3,
    unstable: 2,
    dissipating: 1,
  };

  const a = typeValue[rowA.Type];
  const b = typeValue[rowB.Type];

  if (a > b) return 1;
  if (a < b) return -1;
  return 0;
};

const filteredAnomalies = (anomalies, filters) => {
  return anomalies.filter((anomaly) => {
    if (filters.experience && anomaly.ExperienceBoost < 1) return null;
    if (filters.credits && anomaly.CreditsBoost < 1) return null;
    if (filters.spaceDebrisDrop && anomaly.SpaceDebrisDropBoost < 1) return null;
    if (filters.neutroniumDrop && anomaly.NeutroniumDropBoost < 1) return null;
    if (filters.moduleDrop && anomaly.ModuleDropBoost < 1) return null;
    if (filters.statDrop && anomaly.StatDropBoost < 1) return null;
    if (filters.anomalyDrop && anomaly.AnomalyBoost < 1) return null;
    if (filters.improvedQuality && anomaly.ImprovedModuleQuality === false) return null;
    return anomaly;
  });
};

export const AnomaliesPage = () => {
  const { player, updatePlayer, setError } = React.useContext(AuthContext);
  const activeAnomaly = player.ActiveAnomaly;

  const [anomalies, setAnomalies] = React.useState([]);
  const [filters, setFilters] = React.useState({
    experience: false,
    credits: false,
    spaceDebrisDrop: false,
    neutroniumDrop: false,
    moduleDrop: false,
    statDrop: false,
    anomalyDrop: false,
    improvedQuality: false,
  });

  const updateFilter = (filterName) => {
    setFilters({ ...filters, [filterName]: !filters[filterName] });
  };

  const loadAnomalies = React.useCallback(async () => {
    const anomalies = await AnomaliesService.GetUnexplored(setError);
    if (!anomalies) return;

    setAnomalies(anomalies);
  }, [setError, setAnomalies]);

  const activateAnomaly = async (anomaly) => {
    const player = await AnomaliesService.Explore(anomaly.ID, setError);
    if (!player) return;

    loadAnomalies();

    updatePlayer(player);
  };

  const researchAnomaly = async (anomaly) => {
    const result = await AnomaliesService.Research(anomaly.ID, setError);
    if (!result) {
      loadAnomalies();
      return;
    }

    const { Player: player, BlueprintsGained: gained } = result;

    toast.success(`You gained ${gained} blueprint(s) from researching an anomaly`);

    updatePlayer(player);

    loadAnomalies();
  };

  const showSalesMenu = (anomaly) => {
    toast.error("This will be implemented soon", 1500);
  };

  React.useEffect(() => {
    loadAnomalies();
  }, [loadAnomalies]);

  const columns = [
    {
      name: "Type",
      selector: (anomaly) => anomaly.Type,
      cell: (anomaly) => <AnomalyPopup anomaly={anomaly} />,
      sortable: true,
      sortFunction: anomalyTypeSort,
    },
    {
      name: "Ticks",
      selector: (anomaly) => anomaly.RemainingActions,
      sortable: true,
    },
    {
      name: "Experience",
      selector: (anomaly) => anomaly.ExperienceBoost,
      cell: (anomaly) => ColorizeModifier(anomaly.ExperienceBoost),
      sortable: true,
    },
    {
      name: "Credits",
      selector: (anomaly) => anomaly.CreditsBoost,
      cell: (anomaly) => ColorizeModifier(anomaly.CreditsBoost),
      sortable: true,
    },
    {
      name: "Space debris",
      selector: (anomaly) => anomaly.SpaceDebrisDropBoost,
      cell: (anomaly) => ColorizeModifier(anomaly.SpaceDebrisDropBoost),
      sortable: true,
    },
    {
      name: "Neutronium",
      selector: (anomaly) => anomaly.NeutroniumDropBoost,
      cell: (anomaly) => ColorizeModifier(anomaly.NeutroniumDropBoost),
      sortable: true,
    },
    {
      name: "Equipment",
      selector: (anomaly) => anomaly.ModuleDropBoost,
      cell: (anomaly) => ColorizeModifier(anomaly.ModuleDropBoost),
      sortable: true,
    },
    {
      name: "Stats",
      selector: (anomaly) => anomaly.StatDropBoost,
      cell: (anomaly) => ColorizeModifier(anomaly.StatDropBoost),
      sortable: true,
    },
    {
      name: "Anomalies",
      selector: (anomaly) => anomaly.AnomalyBoost,
      cell: (anomaly) => ColorizeModifier(anomaly.AnomalyBoost),
      sortable: true,
    },
    {
      button: true,
      cell: (anomaly) => (
        <Button
          size="tiny"
          compact
          disabled={!!activeAnomaly}
          title="Start exploring this anomaly, which will affect your battles with the shown modifiers"
          onClick={() => activateAnomaly(anomaly)}
        >
          Explore
        </Button>
      ),
    },
    {
      button: true,
      cell: (anomaly) => (
        <Button size="tiny" compact title="List this anomaly on the exchange for a price that you decide" onClick={() => showSalesMenu(anomaly)}>
          Sell
        </Button>
      ),
    },
    {
      button: true,
      cell: (anomaly) => (
        <Button
          size="tiny"
          compact
          title="Research this anomaly, gaining a few blueprints and destroying it in the process"
          onClick={() => researchAnomaly(anomaly)}
        >
          Research
        </Button>
      ),
    },
  ];

  return (
    <>
      <h3>Anomalies</h3>

      <Segment inverted>
        <Grid columns={1} centered stackable>
          <Grid.Column textAlign="center">
            {activeAnomaly ? (
              <div>
                You are currently exploring an anomaly!
                <br />
                <br />
                <DisplaySingleAnomaly anomaly={activeAnomaly} />
              </div>
            ) : (
              <div>You are not currently exploring an anomaly</div>
            )}
          </Grid.Column>
        </Grid>
      </Segment>

      <span>
        Show <strong>only</strong> anomalies that have positive values for the following modifiers:
      </span>

      <FilterWrapper>
        <Checkbox checked={filters.experience} label="Experience" onChange={() => updateFilter("experience")} />
        <Checkbox checked={filters.credits} label="Credits" onChange={() => updateFilter("credits")} />
        <Checkbox checked={filters.spaceDebrisDrop} label="Space debris" onChange={() => updateFilter("spaceDebrisDrop")} />
        <Checkbox checked={filters.neutroniumDrop} label="Neutronium" onChange={() => updateFilter("neutroniumDrop")} />
        <Checkbox checked={filters.moduleDrop} label="Equipment drop rate" onChange={() => updateFilter("moduleDrop")} />
        <Checkbox checked={filters.statDrop} label="Stats drop rate" onChange={() => updateFilter("statDrop")} />
        <Checkbox checked={filters.anomalyDrop} label="Anomaly detection rate" onChange={() => updateFilter("anomalyDrop")} />
        <Checkbox checked={filters.improvedQuality} label="Equipment quality improvement" onChange={() => updateFilter("improvedQuality")} />
      </FilterWrapper>

      <DataTable dense pagination theme="dark" columns={columns} data={filteredAnomalies(anomalies, filters)} />
    </>
  );
};

const FilterWrapper = styled.div`
  padding: 10px;
`;
