import React from "react";
import { Divider, Grid } from "semantic-ui-react";

import { AuthContext } from "components/utils/AuthorizationContext/AuthorizationContext";
import { WithAuthService } from "services";
import { BoostBuyer } from "../ui-elements";

export class BoostsPage extends React.Component {
  static contextType = AuthContext;

  handlePurchase = ({ type, count }) => {
    WithAuthService.doRequest({
      method: "PUT",
      url: "players/purchase-boost",
      body: {
        BoostType: type,
        BoostCount: count,
      },
    })
      .then((response) => {
        if (response.result.status === 400) {
          this.context.setError(response.result.body.Message, 1000);
          return;
        }

        if (response.result.status !== 200) {
          this.context.setError("Could not purchase boost because of an unknown error", 1000);
          return;
        }

        if (response.result.status === 200) {
          // it went well! we need to update the state!
          this.context.updatePlayer(response.result.body.Player);
        }
      })
      .catch((error) => {
        this.context.setError(error, 1000);
      });
  };

  render() {
    let player = this.context.player;

    return (
      <>
        <h3>Base boosts</h3>
        <Grid className="boosts-category" stackable>
          <Grid.Row>
            <Grid.Column width={8} title="Each individual purchase increases your base damage by 50">
              <BoostBuyer
                player={player}
                currentLevel={player.BaseDamage / 50}
                title="Base damage"
                costType={"credits"}
                playerProp={"BaseDamage"}
                playerPropDivider={0.02}
                playerPropPostfix={""}
                increaseBy={"Increase once (by 50)"}
                boostType={"base-damage"}
                purchaseHandler={this.handlePurchase}
              />
            </Grid.Column>
            <Grid.Column width={8} title="Each individual purchase increases your base armor by 25">
              <BoostBuyer
                player={player}
                currentLevel={player.BaseArmor / 25}
                title="Base armor"
                costType={"credits"}
                playerProp={"BaseArmor"}
                playerPropDivider={0.04}
                playerPropPostfix={""}
                increaseBy={"Increase once (by 25)"}
                boostType={"base-armor"}
                purchaseHandler={this.handlePurchase}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <br />

        <Divider />

        <h3>Space debris boosts</h3>
        <Grid className="boosts-category" stackable>
          <Grid.Row>
            <Grid.Column width={8} title="Each individual purchase increases your critical hit chance by an additional 0.1%">
              <BoostBuyer
                player={player}
                currentLevel={player.CriticalHitChance}
                title="Critical Hit Chance"
                costType={"space debris"}
                playerProp={"CriticalHitChance"}
                playerPropDivider={10}
                playerPropPostfix={"%"}
                increaseBy={"Increase by 0.1%"}
                boostType={"critical-hit-chance"}
                purchaseHandler={this.handlePurchase}
              />
            </Grid.Column>
            <Grid.Column width={8} title="Each individual purchase increases your critical hit damage by an additional 0.1%">
              <BoostBuyer
                player={player}
                currentLevel={player.CriticalHitDamage}
                title="Critical Hit Damage"
                costType={"space debris"}
                playerProp={"CriticalHitDamage"}
                playerPropDivider={10}
                playerPropPostfix={"%"}
                increaseBy={"Increase by 0.1%"}
                boostType={"critical-hit-damage"}
                purchaseHandler={this.handlePurchase}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={8} title="Each individual purchase increases your maximum hitpoints by an additional 0.05%">
              <BoostBuyer
                player={player}
                currentLevel={player.MaxHitpointsBoost}
                title="Maximum hitpoints boost"
                costType={"space debris"}
                playerProp={"MaxHitpointsBoost"}
                playerPropDivider={20}
                playerPropPostfix={"%"}
                increaseBy={"Increase by 0.05%"}
                boostType={"max-hitpoints-boost"}
                purchaseHandler={this.handlePurchase}
              />
            </Grid.Column>
            <Grid.Column width={8} title="Each individual purchase increases your chance for an attempt at an extra hit per round by an additional 0.1%">
              <BoostBuyer
                player={player}
                currentLevel={player.MultiHitChanceBoost}
                title="Multi hit chance boost"
                costType={"space debris"}
                playerProp={"MultiHitChanceBoost"}
                playerPropDivider={10}
                playerPropPostfix={"%"}
                increaseBy={"Increase by 0.1%"}
                boostType={"multi-hit-chance"}
                purchaseHandler={this.handlePurchase}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={8} title="Each individual purchase increases your accuracy by an additional 0.05%">
              <BoostBuyer
                player={player}
                currentLevel={player.AccuracyBoost}
                title="Accuracy boost"
                costType={"space debris"}
                playerProp={"AccuracyBoost"}
                playerPropDivider={20}
                playerPropPostfix={"%"}
                increaseBy={"Increase by 0.05%"}
                boostType={"accuracy-boost"}
                purchaseHandler={this.handlePurchase}
              />
            </Grid.Column>
            <Grid.Column width={8} title="Each individual purchase increases your evasion by an additional 0.05%">
              <BoostBuyer
                player={player}
                currentLevel={player.EvasionBoost}
                title="Evasion boost"
                costType={"space debris"}
                playerProp={"EvasionBoost"}
                playerPropDivider={20}
                playerPropPostfix={"%"}
                increaseBy={"Increase by 0.05%"}
                boostType={"evasion-boost"}
                purchaseHandler={this.handlePurchase}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={8} title="Each individual purchase increases your damage by an additional 0.05%">
              <BoostBuyer
                player={player}
                currentLevel={player.WeaponsMasteryBoost}
                title="Damage boost"
                costType={"space debris"}
                playerProp={"WeaponsMasteryBoost"}
                playerPropDivider={20}
                playerPropPostfix={"%"}
                increaseBy={"Increase by 0.05%"}
                boostType={"damage-boost"}
                purchaseHandler={this.handlePurchase}
              />
            </Grid.Column>
            <Grid.Column width={8} title="Each individual purchase increases your armor by an additional 0.05%">
              <BoostBuyer
                player={player}
                currentLevel={player.ArmorMasteryBoost}
                title="Armor boost"
                costType={"space debris"}
                playerProp={"ArmorMasteryBoost"}
                playerPropDivider={20}
                playerPropPostfix={"%"}
                increaseBy={"Increase by 0.05%"}
                boostType={"armor-boost"}
                purchaseHandler={this.handlePurchase}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <br />

        <Divider />

        <h3>Neutronium boosts</h3>
        <Grid className="boosts-category" stackable>
          <Grid.Row>
            <Grid.Column width={8} title="Each individual purchase increases your maximum actions by 1">
              <BoostBuyer
                player={player}
                currentLevel={player.MaximumActions}
                title="Maximum actions"
                costType={"neutronium"}
                playerProp={"MaximumActions"}
                playerPropDivider={1}
                playerPropPostfix={""}
                increaseBy={"Increase by 1"}
                boostType={"maximum-actions"}
                purchaseHandler={this.handlePurchase}
              />
            </Grid.Column>
            <Grid.Column width={8} title="Each individual purchase increases your experience earned per kill by an additional 1%">
              <BoostBuyer
                player={player}
                currentLevel={player.GlobalExperienceBoost}
                title="Experience boost"
                cap={750}
                costType={"neutronium"}
                playerProp={"GlobalExperienceBoost"}
                playerPropDivider={1}
                playerPropPostfix={""}
                increaseBy={"Increase by 1%"}
                boostType={"global-experience"}
                purchaseHandler={this.handlePurchase}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={8} title="Each individual purchase increases your credits earned per kill by an additional 1%">
              <BoostBuyer
                player={player}
                currentLevel={player.GlobalCreditsBoost}
                title="Credits boost"
                cap={750}
                costType={"neutronium"}
                playerProp={"GlobalCreditsBoost"}
                playerPropDivider={1}
                playerPropPostfix={""}
                increaseBy={"Increase by 1%"}
                boostType={"global-credits"}
                purchaseHandler={this.handlePurchase}
              />
            </Grid.Column>
            <Grid.Column width={8} title="Each individual purchase increases your chance to find Neutronium and ship modules by an additional 1%">
              <BoostBuyer
                player={player}
                currentLevel={player.GlobalDropBoost}
                title="Global drop boost"
                cap={100}
                costType={"neutronium"}
                playerProp={"GlobalDropBoost"}
                playerPropDivider={1}
                playerPropPostfix={""}
                increaseBy={"Increase by 1%"}
                boostType={"global-drop"}
                purchaseHandler={this.handlePurchase}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={8} title="Each individual purchase increases your chance to gain a stat per kill by an additional 1%">
              <BoostBuyer
                player={player}
                currentLevel={player.GlobalStatBoost}
                title="Stat drop boost"
                cap={100}
                costType={"neutronium"}
                playerProp={"GlobalStatBoost"}
                playerPropDivider={1}
                playerPropPostfix={""}
                increaseBy={"Increase by 1%"}
                boostType={"global-stat"}
                purchaseHandler={this.handlePurchase}
              />
            </Grid.Column>
            <Grid.Column
              width={8}
              title="Each individual purchase increases your chance to find space debris, as well as the found amount, per kill by an additional 1%"
            >
              <BoostBuyer
                player={player}
                currentLevel={player.GlobalSpaceDebrisBoost}
                title="Space debris boost"
                cap={100}
                costType={"neutronium"}
                playerProp={"GlobalSpaceDebrisBoost"}
                playerPropDivider={1}
                playerPropPostfix={""}
                increaseBy={"Increase by 1%"}
                boostType={"global-space-debris"}
                purchaseHandler={this.handlePurchase}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    );
  }
}
