import React from "react";
import { Popup } from "semantic-ui-react";

export class UserLink extends React.PureComponent {
  prefillChatInput = (command) => {
    const chatInput = document.getElementById("chat-message-input-bar");
    chatInput.value = command;
    chatInput.focus();
  };

  whisperPlayer = (e) => {
    e.preventDefault();
    const username = this.props.username;
    this.prefillChatInput(`/w ${username} `);
    return false;
  };

  wirePlayer = (e) => {
    e.preventDefault();
    const username = this.props.username;
    this.prefillChatInput(`/wire ${username} `);
    return false;
  };

  render = () => {
    const { brackets, username } = this.props;

    return (
      <Popup
        inverted
        hideOnScroll
        size="small"
        position="top center"
        on={["click"]}
        trigger={
          <span className="username">
            {brackets && "["}
            {username}
            {brackets && "]"}
          </span>
        }
      >
        <p>
          View profile (soon!) <br />
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="#" onClick={this.whisperPlayer}>
            Send whisper
          </a>{" "}
          <br />
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="#" onClick={this.wirePlayer}>
            Wire currency
          </a>{" "}
          <br />
        </p>
      </Popup>
    );
  };
}
