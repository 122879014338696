import React from "react";
import { List, Segment } from "semantic-ui-react";

import { BaseService } from "services";
import { FormatThousands } from "../utils/Helpers/Formatters";
import { UserLink } from "../ui-elements";

export class OnlinePlayersPage extends React.Component {
  state = {
    onlinePlayers: [],
  };

  componentDidMount() {
    BaseService.doRequest({
      method: "GET",
      url: "game/stats/online-players",
    }).then((response) => {
      if (response.result.status === 200) {
        this.setState({
          onlinePlayers: response.result.body,
        });
      }
    });
  }

  render() {
    let players = this.state.onlinePlayers;

    // TODO convert this page to use sortable datatable

    return (
      <Segment inverted>
        <List>
          {players.map((player) => (
            <List.Item key={player.ID}>
              <UserLink brackets={false} username={player.Username} /> - {player.Rank} - <strong>Level {player.Level}</strong> -
              <em>{FormatThousands(player.Experience, 2)} experience in this level</em> - {player.RemainingActionsPercentage}% of actions remaining
            </List.Item>
          ))}
        </List>
      </Segment>
    );
  }
}
