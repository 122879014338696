import React from "react";
import { Table } from "semantic-ui-react";
import { AuthContext } from "components/utils/AuthorizationContext/AuthorizationContext";
import { FormatThousands } from "components/utils/Helpers/Formatters";

export class StatsPage extends React.Component {
  static contextType = AuthContext;

  render() {
    let { player } = this.context;

    if (!player.ModifiedAccuracy) {
      return <div>Loading...</div>;
    }

    return (
      <>
        <Table inverted celled striped compact="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan="3">Pilot fighting attributes</Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Attribute</Table.HeaderCell>
              <Table.HeaderCell>Raw stat</Table.HeaderCell>
              <Table.HeaderCell>Modified total</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Multi hit chance</Table.Cell>
              <Table.Cell>{(player.MultiHitChanceBoost / 10).toFixed(1)}%</Table.Cell>
              <Table.Cell>{(player.ModifiedMultiHitChance / 10).toFixed(1)}%</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Critical hit chance</Table.Cell>
              <Table.Cell>{(player.CriticalHitChance / 10).toFixed(1)}%</Table.Cell>
              <Table.Cell>{(player.ModifiedCriticalHitChance / 10).toFixed(1)}%</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Critical damage increase</Table.Cell>
              <Table.Cell>{player.CriticalHitDamage / 10}%</Table.Cell>
              <Table.Cell>{((player.ModifiedCriticalHitDamageMultiplier - 1) * 100).toFixed(0)}%</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Damage</Table.Cell>
              <Table.Cell>{player.WeaponsMastery} Weapons mastery</Table.Cell>
              <Table.Cell>{FormatThousands(player.ModifiedAverageDamage, 2)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Effective average damage per round (including crits, ignoring accuracy)</Table.Cell>
              <Table.Cell colSpan={2}>{FormatThousands(player.EffectiveDamage, 2)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Armor</Table.Cell>
              <Table.Cell>{player.ArmorMastery} Hull composition</Table.Cell>
              <Table.Cell>
                {FormatThousands(player.ModifiedArmor, 2)} ({(player.DamageReduction * 100).toFixed(3)}% reduced incoming damage)
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Effective health (including damage reduction)</Table.Cell>
              <Table.Cell colSpan={2}>{FormatThousands(player.EffectiveHealth, 2)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Accuracy</Table.Cell>
              <Table.Cell>{player.Accuracy} Accuracy</Table.Cell>
              <Table.Cell>{FormatThousands(player.ModifiedAccuracy.toFixed(0), 2)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Evasion</Table.Cell>
              <Table.Cell>{player.Evasion} Evasion</Table.Cell>
              <Table.Cell>{FormatThousands(player.ModifiedEvasion.toFixed(0), 2)}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        <Table inverted celled striped compact="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan="2">All-time statistics</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Total actions done</Table.Cell>
              <Table.Cell>{FormatThousands(player.TotalActionsDone, 2)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Total wins</Table.Cell>
              <Table.Cell>{FormatThousands(player.TotalWins, 2)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Total deaths</Table.Cell>
              <Table.Cell>{FormatThousands(player.TotalLosses, 2)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Experience gained</Table.Cell>
              <Table.Cell>{FormatThousands(player.TotalExperienceGained, 2)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Credits gained</Table.Cell>
              <Table.Cell>{FormatThousands(player.TotalCreditsGained, 2)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Space debris gained</Table.Cell>
              <Table.Cell>{FormatThousands(player.TotalSpaceDebrisGained, 3)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Neutronium gained</Table.Cell>
              <Table.Cell>{FormatThousands(player.TotalNeutroniumGained, 3)}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </>
    );
  }
}
