import React from "react";
import { Grid, Button } from "semantic-ui-react";

import { AuthContext } from "components/utils/AuthorizationContext/AuthorizationContext";
import { ShipModuleLink } from "../ui-elements";
import { WithAuthService } from "../../services";

export class ShopPage extends React.PureComponent {
  static contextType = AuthContext;

  purchase = (type) => {
    const { updatePlayer, setError } = this.context;

    WithAuthService.doRequest({
      method: "POST",
      url: "players/purchase-basic-module",
      body: {
        ModuleType: type,
      },
    })
      .then((response) => {
        if (response.result.status === 200) {
          updatePlayer(response.result.body);
          return;
        }

        setError("Could not buy basic module: " + response.result.body.Message, 1500);
      })
      .catch((error) => {
        setError(error, 1500);
      });
  };

  render() {
    let { player } = this.context;

    const purchaseCost = (1000 * player.Level).toLocaleString("nl-NL");

    return (
      <>
        <h1>Shop</h1>

        <h3>Basic Modules</h3>
        <p>
          Here, you can buy all basic module types at a price of 1000 Credits per character level. Be warned, these modules do not recycle for any reward.
          Clicking a module will only show a preview, the actual roll of the module you receive is not the same as what is shown in the preview! It could
          contain completely different flat stats and/or additional actions.
        </p>

        <Grid>
          <Grid.Row>
            <Grid.Column width="8">
              <ShipModuleLink id={0} showIcon={true} quality="basic" type="plasma-round-generator" level={player.Level} />
            </Grid.Column>
            <Grid.Column width="8">
              <Button
                onClick={() => {
                  this.purchase("plasma-round-generator");
                }}
              >
                Buy for {purchaseCost} Credits
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="8">
              <ShipModuleLink id={0} showIcon={true} quality="basic" type="flares" level={player.Level} />
            </Grid.Column>
            <Grid.Column width="8">
              <Button
                onClick={() => {
                  this.purchase("flares");
                }}
              >
                Buy for {purchaseCost} Credits
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="8">
              <ShipModuleLink id={0} showIcon={true} quality="basic" type="tracking" level={player.Level} />
            </Grid.Column>
            <Grid.Column width="8">
              <Button
                onClick={() => {
                  this.purchase("tracking");
                }}
              >
                Buy for {purchaseCost} Credits
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="8">
              <ShipModuleLink id={0} showIcon={true} quality="basic" type="armor-plating" level={player.Level} />
            </Grid.Column>
            <Grid.Column width="8">
              <Button
                onClick={() => {
                  this.purchase("armor-plating");
                }}
              >
                Buy for {purchaseCost} Credits
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="8">
              <ShipModuleLink id={0} showIcon={true} quality="basic" type="shield-generator" level={player.Level} />
            </Grid.Column>
            <Grid.Column width="8">
              <Button
                onClick={() => {
                  this.purchase("shield-generator");
                }}
              >
                Buy for {purchaseCost} Credits
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="8">
              <ShipModuleLink id={0} showIcon={true} quality="basic" type="tractor-beam" level={player.Level} />
            </Grid.Column>
            <Grid.Column width="8">
              <Button
                onClick={() => {
                  this.purchase("tractor-beam");
                }}
              >
                Buy for {purchaseCost} Credits
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="8">
              <ShipModuleLink id={0} showIcon={true} quality="basic" type="turret" level={player.Level} />
            </Grid.Column>
            <Grid.Column width="8">
              <Button
                onClick={() => {
                  this.purchase("turret");
                }}
              >
                Buy for {purchaseCost} Credits
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="8">
              <ShipModuleLink id={0} showIcon={true} quality="basic" type="crypto-miner" level={player.Level} />
            </Grid.Column>
            <Grid.Column width="8">
              <Button
                onClick={() => {
                  this.purchase("crypto-miner");
                }}
              >
                Buy for {purchaseCost} Credits
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="8">
              <ShipModuleLink id={0} showIcon={true} quality="basic" type="threat-modeling" level={player.Level} />
            </Grid.Column>
            <Grid.Column width="8">
              <Button
                onClick={() => {
                  this.purchase("threat-modeling");
                }}
              >
                Buy for {purchaseCost} Credits
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="8">
              <ShipModuleLink id={0} showIcon={true} quality="basic" type="portable-mass-compressor" level={player.Level} />
            </Grid.Column>
            <Grid.Column width="8">
              <Button
                onClick={() => {
                  this.purchase("portable-mass-compressor");
                }}
              >
                Buy for {purchaseCost} Credits
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <h3>Other categories............?</h3>

        <p>Neutronium-based serverwide boosts? Global drop? Global multiplier? Global stat boost?</p>
      </>
    );
  }
}
