import React from "react";
import { AuthContext } from "components/utils/AuthorizationContext/AuthorizationContext";
import { Grid, Icon } from "semantic-ui-react";
import { FormatThousands } from "../utils/Helpers/Formatters";

export class CurrencyTracker extends React.PureComponent {
  static contextType = AuthContext;

  render = () => {
    const { player, loggedIn } = this.context;
    if (!loggedIn) {
      return null;
    }

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <p data-tooltip={player.Credits.toLocaleString("nl-NL") + " Credits"}>
              <Icon name="credit card" />
              <strong>{FormatThousands(player.Credits, 2)}</strong>
            </p>
          </Grid.Column>

          <Grid.Column width={8}>
            <p data-tooltip={player.SpaceDebris.toLocaleString("nl-NL") + " Space Debris"}>
              <Icon name="recycle" />
              <strong>{FormatThousands(player.SpaceDebris, 2)}</strong>
            </p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <p data-tooltip={player.Neutronium.toLocaleString("nl-NL") + " Neutronium"}>
              <Icon name="flask" />
              <strong>{player.Neutronium}</strong>
            </p>
          </Grid.Column>

          <Grid.Column width={8}>
            <p data-tooltip={player.PlanetUnspentBlueprints.toLocaleString("nl-NL") + " Blueprints"}>
              <Icon name="file code outline" />
              <strong>{player.PlanetUnspentBlueprints}</strong>
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };
}
