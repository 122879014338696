import * as React from "react";
import { Divider, Tab } from "semantic-ui-react";
import styled from "styled-components";

const state = {
  announcementItems: [
    {
      Title: "Anomalies, enemy rebalancing and removal of bot-checks",
      PostedAt: "30-08-2022",
      PostedByName: "Elnaeth",
      Content: (
        <p>
          Enemies have been rebalanced! There are now 1 million different enemies to choose from, they no longer have unique names. There are too many changes
          to their stats to note here, and you will have to figure out what enemy you can now fight most optimally, sorry.
          <br />
          <br />
          Anomalies have been introduced! They also have a new neutronium-based boost to help increase the quantity of anomalies you find. Anomalies are special
          areas in space where various modifiers change a number of aspects of the rewards for your fights. Anomalies will be able to be sold on the exchange in
          a later patch, for now you can only get rid of them by exploring them or researching them (for a variable amount of extra homeplanet blueprints!).
          <br />
          <br />
          Lastly, there are a number of QoL changes, once again too many to list here, but the most obvious one will be the removal of bot-checks!
          <br />
          <br />
          Enjoy.
        </p>
      ),
    },
    {
      Title: "Modules changes",
      PostedAt: "17-06-2021",
      PostedByName: "Elnaeth",
      Content: (
        <p>
          Modules have been given an overhaul! Once again, as in the past, it is no longer possible to equip more than one of each at a time for the following
          module types (the 'economy modules'): mass compressor, crypto miner or tractor beam. All of these modules have been forcibly unequipped, so do check
          your inventory to equip your best ones if you feel like it.
          <br />
          <br />
          Furthermore, all modules will now be able to give you additional actions, with higher level and higher quality modules giving more actions. Module
          levels can also now roll up to 10% over your current level, up to a cap of 5.000 levels higher. When they do so, their requirement level is not
          affected.
          <br />
          <br />
          Another addition to modules is that they now give you base stats as well, these are not affected by overclocking the module. This is in preparation of
          a major rebalancing of all enemies, with much less exponential power increases, giving players a lot more progression, for that sweet sweet dopamine
          rush.
          <br />
          <br />
          Drop rates for space debris, stats, neutronium and modules have all been slightly increased, meaning you should find all of them a little more often.
          <br />
          <br />
          Oh, and the captcha botchecks have been removed from a number of places, making the interface a bit more fluid and responsive again. This is also in
          preparation of a different method being introduced in the future, as well as new anti-cheating methods and moderators being added soon.
        </p>
      ),
    },
    {
      Title: "Backend rewrite, QoL features, balancing changes",
      PostedAt: "19-03-2021",
      PostedByName: "Elnaeth",
      Content: (
        <p>
          After a long while of no development, I've started rewriting the entire backend service for the game in C#, in a different architecture. This took
          about 2 to 3 months of on and off effort, and it is now completely finished. This should (hopefully) speed up future development, and make it easier
          to roll out new features. And yes, new features are planned right now.
          <br />
          <br />
          As a result of the rewrite, some QoL (more or less) features and bug fixes have also been tackled: <br />
          <br />
          - sliders for easier mass boost purchasing
          <br />
          - mouseover on timestamps in chat to see the date it was said
          <br />
          - rebalancing, enemies now dodge more and are more accurate, hit less hard, and have less hp
          <br />
          - scaling in the beginning of the game has been adjusted, you should no longer jump hundreds of enemies at the start
          <br />
          - player maximum damage reduction through armor has gone up, but it has also been rescaled to be a lot harder to achieve
          <br />
          - neutronium prices for additional actions have been increased quite a lot
          <br />
          - drop rate for neutronium and space debris has been lowered significantly, to incentivize trading
          <br />
          - you may now equip more than 1 economic module (for more credits or more space debris)
          <br />
          - a module filter has been added in the inventory (you can type partial module types or qualities to see only those)
          <br />
          - mass disassemble now previews the amount of earnings
          <br />
          - base boosts (armor and damage) have been made a LOT cheaper
          <br />
        </p>
      ),
    },
    {
      Title: "Basic modules shop, small QoL changes",
      PostedAt: "02-10-2019",
      PostedByName: "Elnaeth",
      Content: (
        <p>
          There is now a new menu item, the shop. For now, the shop stocks each type of module at a new (lowest) level of quality, the basic modules. These are
          meant for those unfortunate souls deemed unworthy of receiving the many gracious gifts of the RNG gods. <br />
          <br />
          Keep an eye out, for the shop will also start selling some other interesting things in the future.
          <br />
          <br />
          Lastly, you can now double-click on any module to automatically paste a link to it in your chatbox, and you may now press the "R" button on your
          keyboard to refresh your actions.
        </p>
      ),
    },
    {
      Title: "QoL/UI fixes, new space debris boost, other tweaks",
      PostedAt: "31-08-2019",
      PostedByName: "Elnaeth",
      Content: (
        <p>
          The game has received another round of several UI tweaks, both for desktop and mobile users, such as less thick scrollbars, leaving more space for
          actual game UI. It should be a definite upgrade for most of us! <br />
          <br />
          The ceiling on experience and credits boost has been increased, they now both go to a considerable 750 percent. This might still not be the actual
          limit, time will tell.
          <br />
          <br />
          Furthermore, another space-debris based boost has been added, possibly the most powerful one yet: maximum hitpoints. Have fun!
          <br />
          <br />
          To help new players find them sooner, the ordering of the boosts page has been reversed, with base boosts now residing at the top. Also, their pricing
          system has been adjusted to be relevant for a longer time at a lower pricing scheme.
          <br />
          <br />
          Base stats have been given a 50% increase in effectivity, making stat boost worth pursuing earlier. Again, this might not be the last balancing change
          in this area, and time will tell.
        </p>
      ),
    },
    {
      Title: "Rudimentary recent combat statistics log added",
      PostedAt: "22-08-2019",
      PostedByName: "Elnaeth",
      Content: (
        <p>
          As you will have noticed if you can read this message, there is now a recent combat statistics log under every battle report. No longer will Astrum
          have to use a plugin to do this! Rejoice!
          <br />
          <br />
          <small>Oh... And cooler stuff will soon land in the game!</small>
        </p>
      ),
    },
    {
      Title: "Mass disassemble, slight high-end armor balancing patch",
      PostedAt: "10-08-2019",
      PostedByName: "Elnaeth",
      Content: (
        <p>
          Mass disassembly of items has been added, it can be found quite prominently in the modules page. <br />
          <br />
          Vincent managed to find a way to become invincible by stacking large amounts of armor, this clever use of game mechanics has been removed or at least
          made less amazing.
          <br />
          <br />
          Due to popular demand, rolls have been moved to a new channel, the games channel. All casino activities shall henceforth be performed in that channel.
          <br />
          <br />
          Preparations have been made for admins to add global double time, be on the lookout for that soon!
        </p>
      ),
    },
    {
      Title: "Chat rework and persistence, whispering and roll command, online player count, mass disassemble",
      PostedAt: "27-07-2019",
      PostedByName: "Elnaeth",
      Content: (
        <p>
          Gone is the time where players can't see how many other people are online! The roman numeral XXX online players has been replaced by an actual count.
          It gets updated with each login/logout. <br />
          <br />
          In other news, the chat system has been reworked, it is no longer possible to spoof chat messages on certain channels. Commands (such as /w, /whisper,
          /roll or /wire) are now accepted on each channel, and they should give you error messages if you do something wrong.
          <br />
          <br />
          Lastly; a new option is being added to the modules page, to disassemble all modules up to a certain quality. We hope this will land soon.
        </p>
      ),
    },
    {
      Title: "Currency exchange",
      PostedAt: "15-07-2019",
      PostedByName: "Elnaeth",
      Content: (
        <p>
          For all your currency needs, we've added an exchange where you can sell to other players! <br />
          Some QoL features for the market will be added soon after, such as seeing who bought your listings, as well as in-game notifications when a sale has
          taken place.
        </p>
      ),
    },
    {
      Title: "AI engines removed, 5 extra space debris boosts added",
      PostedAt: "27-06-2019",
      PostedByName: "Elnaeth",
      Content: (
        <p>
          After consulting our externally hired mathematical madman himself, we discovered the AI engine module to be much too strong. It has been removed, a
          sad day indeed for all of us. <br />
          <br />
          A suitable replacement has been added in the form of a different type of module. See if you can convince one of the other pilots to let you know which
          one it is ;-)
          <br />
          <br />
          To also offer you solace in these difficult times, a great number of additional space debris boosts have been added! Dust off those tractor beams and
          get farming!
        </p>
      ),
    },
    {
      Title: "Balancing patch for homeplanet",
      PostedAt: "24-06-2019",
      PostedByName: "Elnaeth",
      Content: (
        <p>
          After discussing with players, we've determined that the architects office was incredibly powerful. <br />
          The architects have been told to slack off a bit more, and they've drastically decreased their working hours. <br />
          From now on, you can only level that building to a maximum of 100, and every upgrade decreases the necessary amount by 4, for a total reduction of 50%
          at the cap.
          <br />
          <br />
          In other news, battles are now limited to a maximum of 375 rounds. The cause of this knows who he is.
        </p>
      ),
    },
    {
      Title: "Overclocking modules, balancing",
      PostedAt: "22-06-2019",
      PostedByName: "Elnaeth",
      Content: (
        <p>
          Overclocking items
          <br />
          - no longer is disassembly your only option for old items!
          <br />
          - you can now overclock modules, resulting in a 7.5% stronger item (counted from base, so 10 upgrades = 75% stronger item than when you found it)
          <br />
          - this has an initial cost of 500k credits, doubled each time after that
          <br />
          - yes this gets expensive really fast, yes this is intended, no you're not supposed to keep upgrading your worst items, it's just to help you out if
          RNGesus deems you unworthy of modules
          <br />
          <br />
          Scaling
          <br />
          - items have had another round of adjustments, most should've gone up a little bit in stats, higher-end items have been scaled down
          <br />
          - player attribute (you now benefit more from your attributes) and enemy scaling have also been altered, exp and credits have gone up slightly to
          compensate
          <br />
          <br />
          Captcha
          <br />- captcha frequency has been altered slightly, the reward for solving one (a free extra maximum action) has been reintroduced with a smaller
          chance than before
        </p>
      ),
    },
    {
      Title: "Disassembly of unused modules",
      PostedAt: "21-06-2019",
      PostedByName: "Elnaeth",
      Content: (
        <p>
          It is now possible to disassemble modules you no longer have any use for (or maybe never even had). You get a confirmation before doing so, and it
          will show you how much it will give you.
          <br />
          <br />
          As a side-note; as many of you will have noticed: there were some balancing changes. Enemies got a little stronger, but their rewards have been
          increased to compensate. Furthermore, quality distribution of modules has also been adjusted, so expect to see more higher quality drops in the
          future. Lastly, all modules have been regenerated to also match the new formulas for their modifiers, as these have been toned down slightly for the
          higher quality modules.
        </p>
      ),
    },
    {
      Title: "Items, anti-bot, balancing, home planet",
      PostedAt: "15-06-2019",
      PostedByName: "Elnaeth",
      Content: (
        <p>
          Items have been added! There are 9 types, and 4 quality levels... Item strength is determined by the level required to equip it, and the quality level
          of the item
          <br />
          You can link items in chat, click on an item and then hit the "link in chat" button
          <br />
          When you find an item, it is posted in the chat for all to see
          <br />
          Every 100 levels, up to level 1000, you can install an additional module, up to a maximum of 10
          <br />
          <br />
          The first batch of anti-botting measures have been put in place in the form of a random captcha check upon action refresh.
          <br />
          Additional anti-botting measures will be implemented in future patches, from time to time we will involve the player base in discussions to determine
          the direction we want to take these measures
          <br />
          <br />
          Modified drop quantity for neutronium
          <br />
          Modified drop frequency and quantity for space debris
          <br />
          <br />
          Base armor boost has been upgraded from 10 to 25 per purchase, retroactively for all players
          <br />
          Players now also get a small amount of armor per player level
          <br />
          <br />
          The stats page now also shows your effective health and damage, these do not take the enemy you are fighting into account, they serve just to help
          evaluate if certain modules make you stronger or weaker
          <br />
          <br />
          The homeplanet has been added! Get ready to build your own colony, for more bonuses to all kinds of aspects of your character!
          <br />
          Each building has a different effect, the price for each building is a new type of resource: blueprints.
          <br />
          You find one blueprint for every 1000 kills you perform, and one of the buildings lowers the required amount of kills.
          <br />
          <br />
          Various small changes in the server have been added to enhance performance
        </p>
      ),
    },
    {
      Title: "Lots of updates, and items preparation",
      PostedAt: "07-06-2019",
      PostedByName: "Elnaeth",
      Content: (
        <p>
          A FAQ page has been added to answer some of the more common questions we've seen in the chat lately! <br />
          An alpha-version release notification has been added to the game, this should let people know what to expect! <br />
          The boosts page now disabled any buttons for boosts that you can't afford.
          <br />
          <br />
          The much-anticipated ship modules system is being worked on very hard, this update contains mostly preparations for supporting that system! Stay
          tuned!
          <br />
          <br />
          Lastly, the scaling for enemies has been adjusted quite a lot, see how many new foes you can vanquish on your way to the top! ;-)
        </p>
      ),
    },
    {
      Title: "Stats page added",
      PostedAt: "03-06-2019",
      PostedByName: "Elnaeth",
      Content: (
        <p>
          Hit chances now go to 2 decimals, have fun hitting marginally more often!
          <br />
          Restyle of various UI elements
          <br />
          Added the stats page
          <br />
          Added a login message that only shows up if the player wasn't already connected
          <br />
          Added global announcements for players finding neutronium
          <br />
          Reworked the internal battle simulator to be more prepared for coming updates
          <br />
          Added lifetime statistics tracking
          <br />
        </p>
      ),
    },
    {
      Title: "New features",
      PostedAt: "01-06-2019",
      PostedByName: "Elnaeth",
      Content: (
        <p>
          Armor has been added to the player stats. The base armor boost has also been added. Prices for base boosts (damage and armor) have been lowered quite
          a bit. <br />
          Another feature that has been added, but is not yet 100% visible, is the underlying pilot stats system. Every once in a while you will now learn a
          thing or two during your fights, <br />
          thereby increasing your accuracy, evasion, damage done and reducing the damage you take. <br />
          <br /> A number of QoL UI fixes have also been done, for example you can now see the hit chance versus an enemy, as well as your average damage per
          hit.
        </p>
      ),
    },
  ],
};

export const NewsPage = () => {
  const { announcementItems } = state;

  return (
    <Tab
      menu={{ color: "grey", inverted: true, attached: false, tabular: false }}
      panes={[
        {
          menuItem: "Announcements",
          render: () => (
            <Tab.Pane inverted align={"left"}>
              {announcementItems.map((item, key) => (
                <NewsItem key={key}>
                  <p>
                    {item.Title} - <em>{item.PostedAt}</em> - <strong>{item.PostedByName}</strong>
                  </p>

                  {item.Content}

                  <br />
                  <Divider inverted />
                  <br />
                </NewsItem>
              ))}
            </Tab.Pane>
          ),
        },
      ]}
    />
  );
};

const NewsItem = styled.div``;
