import React from "react";
import { Button, Grid } from "semantic-ui-react";

import { AuthContext } from "components/utils/AuthorizationContext/AuthorizationContext";
import { WithAuthService } from "services";

export class HomePlanetPage extends React.Component {
  static contextType = AuthContext;

  state = {
    cost: {},
  };

  componentDidMount() {
    WithAuthService.doRequest({
      method: "GET",
      url: "players/building-prices",
    })
      .then((response) => {
        this.context.updatePlayer(response.result.body.Player);
        this.setState({
          cost: response.result.body,
        });
      })
      .catch((error) => {
        this.context.setError(error, 1000);
      });
  }

  handlePurchase = (buildingType) => {
    WithAuthService.doRequest({
      method: "PUT",
      url: `players/purchase-building/${buildingType}`,
    })
      .then((response) => {
        if (response.result.status === 400) {
          this.context.setError(response.result.body.Message, 1000);
          return;
        }

        if (response.result.status !== 200) {
          this.context.setError("Could not upgrade building because of an unknown error", 1000);
          return;
        }

        if (response.result.status === 200) {
          // it went well! we need to update the state!
          this.context.updatePlayer(response.result.body.Player);
          this.setState({
            cost: response.result.body,
          });
        }
      })
      .catch((error) => {
        this.context.setError(error, 1000);
      });
  };

  render() {
    let player = this.context.player;
    let cost = this.state.cost;

    let killsPerBlueprint = 800 - 4 * player.PlanetPlansBoostUpgrades;

    return (
      <div>
        <h3>Welcome to your homeplanet</h3>

        <p>
          Unspent blueprints: <em>{player.PlanetUnspentBlueprints}</em>, next due in approximately {killsPerBlueprint - player.KillsSinceBlueprint} kills
        </p>

        <Grid columns={2} centered stackable className="homeplanet-buildings">
          <Grid.Column textAlign="center" width={8}>
            <p data-tooltip="Each upgrade lets you kill 4 fewer enemies to get a new blueprint">
              Architects office - Level {player.PlanetPlansBoostUpgrades}
              <br />
              <small>It takes {killsPerBlueprint} kills to prepare a new blueprint</small>
              <br />
              <em>The more architects you hire, the faster they can work for you!</em>
            </p>

            <p>Upgrade cost: {cost.ArchitectsOffice} blueprints</p>

            <Button
              disabled={cost.ArchitectsOffice > player.PlanetUnspentBlueprints || player.PlanetPlansBoostUpgrades >= 100}
              size="tiny"
              onClick={() => {
                this.handlePurchase("blueprint-drops");
              }}
            >
              Upgrade
            </Button>
          </Grid.Column>
          <Grid.Column textAlign="center" width={8}>
            <p data-tooltip="Each upgrade gives you 10 more maximum actions">
              Hibernation pod - Level {player.PlanetActionBoostUpgrades}
              <br />
              <small>Maximum actions increased by {10 * player.PlanetActionBoostUpgrades}</small>
              <br />
              <em>This beauty is what helps you sleep at night. The better you sleep, the longer you can keep going before needing a powernap!</em>
            </p>

            <p>Upgrade cost: {cost.HibernationPod} blueprints</p>

            <Button
              disabled={cost.HibernationPod > player.PlanetUnspentBlueprints}
              size="tiny"
              onClick={() => {
                this.handlePurchase("maximum-actions");
              }}
            >
              Upgrade
            </Button>
          </Grid.Column>
          <Grid.Column textAlign="center" width={8}>
            <p data-tooltip="Each upgrade gives you 0.5% more experience per kill">
              Research laboratory - Level {player.PlanetExperienceUpgrades}
              <br />
              <small>Total bonus: +{player.PlanetExperienceUpgrades * 0.5}% experience gained</small>
              <br />
              <em>Through their combined efforts, your researchers find new and exciting ways to make you even smarter</em>
            </p>

            <p>Upgrade cost: {cost.ResearchLaboratory} blueprints</p>

            <Button
              disabled={cost.ResearchLaboratory > player.PlanetUnspentBlueprints}
              size="tiny"
              onClick={() => {
                this.handlePurchase("experience");
              }}
            >
              Upgrade
            </Button>
          </Grid.Column>
          <Grid.Column textAlign="center" width={8}>
            <p data-tooltip="Each upgrade gives you 0.5% more credits per kill">
              Interstellar credit agency - Level {player.PlanetCreditUpgrades}
              <br />
              <small>Total bonus: +{player.PlanetCreditUpgrades * 0.5}% credits gained</small>
              <br />
              <em>By providing loans to other planets for a small fee, your networth increases more and more</em>
            </p>

            <p>Upgrade cost: {cost.InterstellarCreditAgency} blueprints</p>

            <Button
              disabled={cost.InterstellarCreditAgency > player.PlanetUnspentBlueprints}
              size="tiny"
              onClick={() => {
                this.handlePurchase("credits");
              }}
            >
              Upgrade
            </Button>
          </Grid.Column>
          <Grid.Column textAlign="center" width={8}>
            <p data-tooltip="Each upgrade gives you 0.25% more chance to increase your stats">
              Meta-human enhancement facility - Level {player.PlanetStatUpgrades}
              <br />
              <small>Total bonus: +{(0.25 * player.PlanetStatUpgrades).toFixed(2)}% chance for a stat</small>
              <br />
              <em>Neurological implants are manufactured here, to help make you the ultimate killing machine</em>
            </p>

            <p>Upgrade cost: {cost.MetaHumanEnhancementFacility} blueprints</p>

            <Button
              disabled={cost.MetaHumanEnhancementFacility > player.PlanetUnspentBlueprints}
              size="tiny"
              onClick={() => {
                this.handlePurchase("stat-drops");
              }}
            >
              Upgrade
            </Button>
          </Grid.Column>
          <Grid.Column textAlign="center" width={8}>
            <p data-tooltip="Each upgrade gives you 0.25% more chance to find modules">
              Planetary gravity generator - Level {player.PlanetModuleDropUpgrades}
              <br />
              <small>Total bonus: +{(0.25 * player.PlanetModuleDropUpgrades).toFixed(2)}% chance for finding modules</small>
              <br />
              <em>By increasing your planet's gravity, you can attract modules that float around in space, remnants of glorious battles</em>
            </p>

            <p>Upgrade cost: {cost.PlanetaryGravityGenerator} blueprints</p>

            <Button
              disabled={cost.PlanetaryGravityGenerator > player.PlanetUnspentBlueprints}
              size="tiny"
              onClick={() => {
                this.handlePurchase("module-drops");
              }}
            >
              Upgrade
            </Button>
          </Grid.Column>
          <Grid.Column textAlign="center" width={8}>
            <p data-tooltip="Each upgrade gives you 0.25% more chance to find Neutronium">
              Large hadron collider - Level {player.PlanetNeutroniumUpgrades}
              <br />
              <small>Total bonus: +{(0.25 * player.PlanetNeutroniumUpgrades).toFixed(2)}% chance for finding neutronium</small>
              <br />
              <em>By colliding particles at incredible speeds and studying the effects, your understanding of Neutronium continues to grow</em>
            </p>

            <p>Upgrade cost: {cost.LargeHadronCollider} blueprints</p>

            <Button
              disabled={cost.LargeHadronCollider > player.PlanetUnspentBlueprints}
              size="tiny"
              onClick={() => {
                this.handlePurchase("neutronium-drops");
              }}
            >
              Upgrade
            </Button>
          </Grid.Column>
          <Grid.Column textAlign="center" width={8}>
            <p data-tooltip="Each upgrade decreases the cost of all space debris upgrades by 0.5%">
              Space debris processing plant - Level {player.PlanetSpaceDebrisUpgrades}
              <br />
              <small>Decreases the cost of all space debris boosts by {(100 * (1 - Math.pow(0.995, player.PlanetSpaceDebrisUpgrades))).toFixed(2)}%</small>
              <br />
              <em>More efficient processing of space debris lets you squeeze more use out of this valuable material</em>
            </p>

            <p>Upgrade cost: {cost.RecyclingPlant} blueprints</p>

            <Button
              disabled={cost.RecyclingPlant > player.PlanetUnspentBlueprints}
              size="tiny"
              onClick={() => {
                this.handlePurchase("space-debris-cost");
              }}
            >
              Upgrade
            </Button>
          </Grid.Column>
          <Grid.Column textAlign="center" width={8}>
            <p data-tooltip="Each upgrade gives you 0.25% more chance to detect anomalies">
              Interferometer - Level {player.PlanetAnomalyUpgrades}
              <br />
              <small>Total bonus: +{(0.25 * player.PlanetAnomalyUpgrades).toFixed(2)}% chance to detect anomalies</small>
              <br />
              <em>By expanding your array of radio antennas you become better at detecting space anomalies</em>
            </p>

            <p>Upgrade cost: {cost.Interferometer} blueprints</p>

            <Button
              disabled={cost.Interferometer > player.PlanetUnspentBlueprints}
              size="tiny"
              onClick={() => {
                this.handlePurchase("anomaly-drops");
              }}
            >
              Upgrade
            </Button>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}
