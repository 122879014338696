import React from "react";
import { Table } from "semantic-ui-react";

import { AuthContext } from "components/utils/AuthorizationContext/AuthorizationContext";
import { FormatThousands } from "../utils/Helpers/Formatters";
import { WithAuthService } from "services";

export class CombatLog extends React.PureComponent {
  static contextType = AuthContext;

  state = {};

  battleResultListener = (result) => {
    this.setState({
      log: result.CombatLog,
    });
  };

  retrieveCombatLog = () => {
    WithAuthService.doRequest({
      method: "GET",
      url: "players/combat-log",
    })
      .then((response) => {
        if (response.result.status === 200) {
          this.setState({
            log: response.result.body,
          });
        }
      })
      .catch((error) => {
        this.context.setError(error, 1000);
      });
  };

  componentDidMount = () => {
    this.retrieveCombatLog();
    this.context.socket.on("BattleResult", this.battleResultListener);
  };

  componentWillUnmount = () => {
    this.context.socket.off("BattleResult", this.battleResultListener);
  };

  resetCombatLog = (e) => {
    e.preventDefault();
    this.context.socket.emit("ResetCombatLog", { Which: "all" });
    this.retrieveCombatLog();
    return false;
  };

  render = () => {
    if (!this.state.log) {
      return <p>Loading...</p>;
    }

    const { log } = this.state;
    let winPercentage = log.Kills < 1 || log.Deaths < 1 ? 100 : 100 * (log.Kills / (log.Kills + log.Deaths));
    if (log.Kills === 0) winPercentage = 0;

    return (
      <>
        <p>
          Combat log (
          <a href="#reset-combat-log" onClick={this.resetCombatLog}>
            reset
          </a>
          )
        </p>

        <Table inverted unstackable celled basic="very" compact="very">
          <Table.Body>
            <Table.Row>
              <Table.Cell>Wins</Table.Cell>
              <Table.Cell>{log.Kills}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Losses</Table.Cell>
              <Table.Cell>{log.Deaths}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Win rate</Table.Cell>
              <Table.Cell>{winPercentage.toFixed(2)}%</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Stats gained</Table.Cell>
              <Table.Cell>{log.Stats}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Modules found</Table.Cell>
              <Table.Cell>{log.Modules}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Anomalies detected</Table.Cell>
              <Table.Cell>{log.Anomalies}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Experience gained</Table.Cell>
              <Table.Cell>
                <p data-tooltip={log.Experience.toLocaleString("nl-NL") + " experience"}>{FormatThousands(log.Experience, 2)}</p>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Credits earned</Table.Cell>
              <Table.Cell>
                <p data-tooltip={log.Credits.toLocaleString("nl-NL") + " credits"}>{FormatThousands(log.Credits, 2)}</p>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Space debris found</Table.Cell>
              <Table.Cell>
                <p data-tooltip={log.SpaceDebris.toLocaleString("nl-NL") + " space debris"}>{FormatThousands(log.SpaceDebris, 2)}</p>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Neutronium found</Table.Cell>
              <Table.Cell>{log.Neutronium}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </>
    );
  };
}
