import {
  AnomaliesPage,
  BattlePage,
  BoostsPage,
  ChangePasswordPage,
  CurrencyExchangePage,
  EquipmentPage,
  FAQPage,
  HighscoresPage,
  HomePage,
  HomePlanetPage,
  LoginPage,
  NewsPage,
  OnlinePlayersPage,
  RegisterPage,
  ShopPage,
  StatsPage,
} from "components/pages";
import { PrivateRoute } from "components/utils/PrivateRoute";
import React from "react";
import { Route, Switch } from "react-router-dom";

const Ranks = {
  Administrator: "Administrator",
  Moderator: "Moderator",
};

export class Routes extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path="/register" component={RegisterPage} />
        <Route exact path="/login" component={LoginPage} />
        <PrivateRoute exact path="/change-password" component={ChangePasswordPage} />

        <Route exact path="/news" component={NewsPage} />
        <Route exact path="/faq" component={FAQPage} />
        <Route exact path="/online-players" component={OnlinePlayersPage} />
        <Route exact path="/highscores" component={HighscoresPage} />

        <PrivateRoute exact path="/stats" component={StatsPage} />
        <PrivateRoute exact path="/homeplanet" component={HomePlanetPage} />
        <PrivateRoute exact path="/equipment" component={EquipmentPage} />
        <PrivateRoute exact path="/anomalies" component={AnomaliesPage} />
        <PrivateRoute exact path="/battle" component={BattlePage} />
        <PrivateRoute exact path="/boosts" component={BoostsPage} />
        <PrivateRoute exact path="/shop" component={ShopPage} />
        <PrivateRoute exact path="/currency-exchange" component={CurrencyExchangePage} />

        {/* Admin-only routes will go here */}
        <PrivateRoute exact path="/admin/manage-news" component={() => <></>} allowedRanks={[Ranks.Administrator]} />

        {/* Moderator pages will go here */}
        <PrivateRoute exact path="/moderate/refresh-log" component={() => <></>} allowedRanks={[Ranks.Administrator, Ranks.Moderator]} />

        {/* all routes that do not match any other component will show the homepage */}
        <Route component={HomePage} />
      </Switch>
    );
  }
}
