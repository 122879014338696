import React from "react";
import { Button, Input } from "semantic-ui-react";
import styled from "styled-components";
import { FormatThousands } from "../utils/Helpers/Formatters";

export class BoostBuyer extends React.Component {
  state = {
    purchaseAmount: 0,
  };

  handlePurchaseAmountChange = (max, newValue) => {
    if (isNaN(newValue)) newValue = 0;
    if (newValue >= max) newValue = max;
    this.setState({ purchaseAmount: parseInt(newValue) });
  };

  nextMaximumActionCost = (forLevel) => {
    return parseInt(2 + (forLevel - 1200) / 300);
  };

  nextCreditBasedBoostCost = (forLevel) => {
    return parseInt(17500 * Number(Math.pow(1.02, forLevel)));
  };

  nextDebrisBasedBoostCost = (forLevel) => {
    return parseInt((150 + 10 * forLevel) * Math.pow(0.995, this.props.player.PlanetSpaceDebrisUpgrades));
  };

  nextIncomeBoostCost = (forLevel) => {
    return parseInt(2 + 1 * (forLevel / 15));
  };

  nextDropBoostCost = (forLevel) => {
    return parseInt(2 + 2 * forLevel);
  };

  maxCostCalculator = (level, startingCurrency, singleUpgradeCalculator) => {
    let startLevel = level;
    let costsForLevel = [0];
    let remainingCurrency = startingCurrency;
    let maxReached = false;
    let totalCost = 0;
    let iteration = 1;

    while (!maxReached || remainingCurrency > 0) {
      let nextCost = singleUpgradeCalculator(level);
      totalCost += nextCost;
      costsForLevel[iteration] = totalCost;

      if (nextCost > remainingCurrency) {
        maxReached = true;
        break;
      }

      iteration++;
      remainingCurrency -= nextCost;
      level++;
    }

    return {
      costsForLevel,
      maxLevel: level - startLevel,
    };
  };

  render = () => {
    let props = this.props;
    let currentLevel = props.currentLevel;
    let currentLevelToRender = currentLevel / props.playerPropDivider;

    let disabled = this.props.cap === currentLevel;

    let nextCost = 0;
    let costs = {};

    switch (props.costType) {
      case "credits":
        nextCost = this.nextCreditBasedBoostCost(currentLevel);
        costs = this.maxCostCalculator(currentLevel, props.player.Credits, this.nextCreditBasedBoostCost);
        break;

      case "space debris":
        nextCost = this.nextDebrisBasedBoostCost(currentLevel);
        costs = this.maxCostCalculator(currentLevel, props.player.SpaceDebris, this.nextDebrisBasedBoostCost);
        break;

      case "neutronium":
        if (props.boostType === "maximum-actions") {
          nextCost = this.nextMaximumActionCost(currentLevel);
          costs = this.maxCostCalculator(currentLevel, props.player.Neutronium, this.nextMaximumActionCost);
          break;
        }

        if (props.boostType === "global-experience" || props.boostType === "global-credits") {
          nextCost = this.nextIncomeBoostCost(currentLevel);
          costs = this.maxCostCalculator(currentLevel, props.player.Neutronium, this.nextIncomeBoostCost);
          break;
        }

        if (props.boostType === "global-drop" || props.boostType === "global-stat" || props.boostType === "global-space-debris") {
          nextCost = this.nextDropBoostCost(currentLevel);
          costs = this.maxCostCalculator(currentLevel, props.player.Neutronium, this.nextDropBoostCost);
        }
        break;

      default:
        break;
    }

    // first, set the cap to the most the player can purchase
    let max = costs.maxLevel;
    if (props.cap) {
      // if there's a hard limit, that's the new max, regardless of how many they can buy
      // but, taking into account how many they already have
      max = props.cap - currentLevel;

      // if the max then still exceeds the maximum they can buy, that again is the limit
      if (max >= costs.maxLevel) max = costs.maxLevel;
    }

    return (
      <Wrapper>
        <p>{props.title}</p>

        <p data-tooltip={nextCost}>
          <strong>{FormatThousands(nextCost, 2)}</strong> {props.costType}
        </p>

        <Button
          size="tiny"
          content={`${currentLevelToRender}${props.playerPropPostfix}`}
          label={props.increaseBy}
          disabled={disabled}
          onClick={() => {
            props.purchaseHandler({ type: props.boostType, count: 1 });
            this.setState({ purchaseAmount: 0 });
          }}
        />

        <br />

        <Input
          style={{ width: "250px" }}
          type="range"
          min={0}
          disabled={disabled}
          max={max}
          value={this.state.purchaseAmount}
          onChange={(e) => this.handlePurchaseAmountChange(max, Number(e.target.value))}
        />

        <br />

        <Input
          style={{ marginBottom: "12px", width: "50px", height: "20px", lineHeight: "20px" }}
          className="centerAlign"
          size="mini"
          type="text"
          step={1}
          min={0}
          disabled={disabled}
          max={max}
          value={this.state.purchaseAmount}
          onChange={(e) => this.handlePurchaseAmountChange(max, Number(e.target.value))}
        />

        <br />

        <Button
          size={"tiny"}
          disabled={disabled}
          onClick={() => {
            props.purchaseHandler({ type: props.boostType, count: this.state.purchaseAmount });
            this.setState({ purchaseAmount: 0 });
          }}
        >
          Increase {this.state.purchaseAmount} times for {FormatThousands(costs.costsForLevel[this.state.purchaseAmount], 2)} {props.costType}
        </Button>
      </Wrapper>
    );
  };
}

const Wrapper = styled.div`
  padding: 15px;
  background-color: #272727 !important;
`;
