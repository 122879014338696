import { AuthorizationConsumer } from "components/utils/AuthorizationContext/AuthorizationContext";
import React from "react";
import { Redirect, Route } from "react-router-dom";

export const PrivateRoute = ({ component: Component, allowedRanks = [], ...rest }) => (
  <AuthorizationConsumer>
    {({ loggedIn, initialLoadDone, player, setError }) => (
      <Route
        render={(props) => {
          if (!initialLoadDone) {
            return <p>Loading...</p>;
          }

          if (!loggedIn) {
            return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
          }

          // explicitly verify the current player has one of the required ranks if they were passed to this component
          if (allowedRanks && allowedRanks.length > 0 && !allowedRanks.includes(player.Rank)) {
            setError("You are not allowed to access this page", 2000);
            return <Redirect to={{ pathname: "/home" }} />;
          }

          return <Component {...props} />;
        }}
        {...rest}
      />
    )}
  </AuthorizationConsumer>
);
