import React from "react";
import hash from "object-hash";
import { AuthContext } from "components/utils/AuthorizationContext/AuthorizationContext";
import { Form, Input, Segment, Tab } from "semantic-ui-react";
import { ChatMessage } from "./ChatMessage";

import "styling/chatbox.css";
import "styling/equipment.css";

export class Chatbox extends React.PureComponent {
  static contextType = AuthContext;

  state = {
    lastReceived: {},
    chatMessages: {
      // fleet: [], // TODO
      general: [],
      trade: [],
      whispers: [],
      global: [],
      games: [],
      errors: [],
    },
    currentChannel: "general",
    currentUser: {
      Username: "",
    },
  };

  constructor(props) {
    super(props);
    this.newMessageInput = React.createRef();
  }

  componentDidMount() {
    if (!this.context.loggedIn || !this.context.socket) {
      return;
    }

    this.context.socket.on("ChatHistory", (data) => {
      this.setState({
        chatMessages: data,
      });

      this.scrollToBottom();
    });

    this.context.socket.on("ChatMessage", (message) => {
      let chatMessages = this.state.chatMessages,
        currentChannel = this.state.currentChannel,
        incomingChannel = message.Channel,
        shouldScroll = false;

      // if it's a message on this channel, we need to scroll down
      if (currentChannel === incomingChannel) shouldScroll = true;

      // add the message to the channel it came in on, if the channel exists
      if (chatMessages[incomingChannel] !== undefined) chatMessages[incomingChannel].push(message);

      // whispers, errors and notifications get pushed to the current channel as well
      if (incomingChannel === "whispers" || message.IsError === true || message.IsNotification === true) {
        shouldScroll = true;
        chatMessages[currentChannel].push(message);
      }

      // push the messages to the state, and make it refresh by force by stetting a top level property
      // as render() doesn't get called for deep-state changes, as it can't go check that
      this.setState({
        chatMessages,
        lastReceived: { time: message.Timestamp },
      });

      // scroll to show the new message if we should
      if (shouldScroll === true) this.scrollToBottom();
    });

    this.setState({
      currentUser: {
        Username: this.context.player.Username,
      },
    });
  }

  sendChatMessage = () => {
    let message = this.newMessageInput.inputRef.current.value;
    if (message === "") {
      return;
    }

    this.context.socket.emit("ChatMessage", {
      Channel: this.state.currentChannel,
      Content: message,
    });

    // clear the input for the next message
    this.newMessageInput.inputRef.current.value = "";
    this.scrollToBottom();
  };

  scrollToBottom = () => {
    let element = document.querySelector(".hidden-latest-message");
    element.scrollIntoView(false);
  };

  renderChannel = (channelName) => {
    let chatMessages = this.state.chatMessages[channelName];

    return (
      <div className={`chat-messages chat-channel-${channelName}`}>
        {chatMessages.map((message) => {
          let classNames = [message.Channel],
            key = `${hash(message)}`;

          if (!message.FromUsername) classNames.push("command-output");
          if (message.IsNotification === true) classNames.push("notification");
          if (message.IsError === true) classNames.push("error");

          // TODO gotta build roles/ranks/titles into this system, it's ridiculous to do this by looking at the names right now
          if (message.FromUsername && (message.FromUsername.toLowerCase() === "elnaeth" || message.FromUsername.toLowerCase() === "wolfhakase"))
            classNames.push("admin");

          return (
            <div key={key} className={classNames.join(" ")}>
              <ChatMessage messageInputRef={this.newMessageInput} currentUserID={this.context.player.ID} message={message} />
            </div>
          );
        })}
        <div className="hidden-latest-message" />
      </div>
    );
  };

  channels = (currentChannel) => {
    return [
      { stateKey: "general", name: "General", active: currentChannel === "general" },
      { stateKey: "trade", name: "Trade", active: currentChannel === "trade" },
      { stateKey: "games", name: "Games", active: currentChannel === "games" },
      { stateKey: "global", name: "Global", active: currentChannel === "global" },
      { stateKey: "whispers", name: "Whispers", active: currentChannel === "whispers" },
    ];
  };

  changeChannel = (data) => {
    this.setState({
      currentChannel: data.panes[data.activeIndex].channel,
    });

    setTimeout(() => {
      this.scrollToBottom();
    }, 10);
  };

  render() {
    const panes = this.channels(this.state.currentChannel).map((channel) => {
      return {
        channel: channel.stateKey,
        key: this.state.chatMessages[channel.stateKey].length,
        menuItem: channel.name,
        render: () => (
          <Tab.Pane inverted align={"left"} key={this.state.chatMessages[channel.stateKey].length} active={channel.active} className="chatbox-wrapper">
            {this.renderChannel(channel.stateKey)}
          </Tab.Pane>
        ),
      };
    });

    return (
      <Segment className="chatbox" inverted>
        <Tab
          menu={{
            color: "grey",
            tabular: true,
            attached: true,
            inverted: true,
            compact: true,
            stackable: true,
            borderless: true,
            size: "mini",
          }}
          className="chat-tab"
          panes={panes}
          onTabChange={(event, data) => {
            this.changeChannel(data);
          }}
        />

        <Form className="chatbox-form" name="chatbox-form" onSubmit={this.sendChatMessage} inverted>
          <Form.Field>
            <Input
              inverted
              name="newMessage"
              size="small"
              autoComplete="off"
              placeholder="Type a new message..."
              id="chat-message-input-bar"
              maxLength="450"
              ref={(ref) => (this.newMessageInput = ref)}
              width={16}
            />
          </Form.Field>
        </Form>
      </Segment>
    );
  }
}
