import styled from "styled-components";

export const DecorateAnomalyType = (type) => {
    if (type === "nexus") return <NexusAnomaly>{type}</NexusAnomaly>;
    if (type === "stable") return <StableAnomaly>{type}</StableAnomaly>;
    if (type === "unstable") return <UnstableAnomaly>{type}</UnstableAnomaly>;
    if (type === "dissipating") return <DissipatingAnomaly>{type}</DissipatingAnomaly>;
    return <>{type}</>;
};

export const PrintModifiers = (anomaly) => (
    <Modifiers>
        {anomaly.ImprovedModuleQuality === true && <li>Higher quality module drops</li>}
        {anomaly.ExperienceBoost !== 0 && <li>{ColorizeModifier(anomaly.ExperienceBoost)} total experience</li>}
        {anomaly.CreditsBoost !== 0 && <li>{ColorizeModifier(anomaly.CreditsBoost)} total credits</li>}
        {anomaly.SpaceDebrisDropBoost !== 0 && <li>{ColorizeModifier(anomaly.SpaceDebrisDropBoost)} space debris drop chance</li>}
        {anomaly.NeutroniumDropBoost !== 0 && <li>{ColorizeModifier(anomaly.NeutroniumDropBoost)} neutronium drop chance</li>}
        {anomaly.ModuleDropBoost !== 0 && <li>{ColorizeModifier(anomaly.ModuleDropBoost)} module drop chance</li>}
        {anomaly.StatDropBoost !== 0 && <li>{ColorizeModifier(anomaly.StatDropBoost)} stat gain chance</li>}
        {anomaly.AnomalyBoost !== 0 && <li>{ColorizeModifier(anomaly.AnomalyBoost)} anomaly discovery chance</li>}
    </Modifiers>
);

export const ColorizeModifier = (modifier) => {
    if (modifier > 0) return <Positive>+{modifier}%</Positive>;
    return <Negative>{modifier}%</Negative>;
};

const Modifiers = styled.ul`
    list-style-type: none;
    margin: 2px 0 2px 0;
    line-height: 16px;
    font-size: 14px;
    padding: 0;
`;

const Positive = styled.span`
    font-weight: bolder;
    color: green;
`;

const Negative = styled.span`
    font-weight: bolder;
    color: red;
`;

const StableAnomaly = styled.span`
    font-weight: bolder;
    color: gold;
    text-transform: capitalize;
`;

const UnstableAnomaly = styled.span`
    font-weight: bolder;

    color: #009fda;
    text-transform: capitalize;
`;

const DissipatingAnomaly = styled.span`
    font-weight: bolder;

    color: gray;
    text-transform: capitalize;
`;

const NexusAnomaly = styled.span`
    color: red;
    font-weight: bolder;
    text-transform: capitalize;
`;
