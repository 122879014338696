import React from "react";
import { Button, Form, Input, Grid, Progress } from "semantic-ui-react";
import { AuthContext } from "components/utils/AuthorizationContext/AuthorizationContext";
import { FormatThousands } from "components/utils/Helpers/Formatters";

export class BattlePage extends React.PureComponent {
  static contextType = AuthContext;

  state = {
    loading: false,
    selectedEnemy: null,
  };

  winTemplate = () => {
    let { PlayerResult, TotalRoundCount, ServerWideDoubleLeft } = this.context.latestBattleResult;

    let doubleActive = false;
    if (ServerWideDoubleLeft !== "00:00:00") {
      doubleActive = true;
    }

    return (
      <div>
        <p>The fight lasted for {TotalRoundCount} rounds, and your ship emerged victorious!</p>

        {doubleActive && (
          <p className="double-rates-active">
            <strong>Double experience and credits</strong>
          </p>
        )}

        <p>
          You gained {PlayerResult.GainedExperience.toLocaleString("nl-NL")} experience and {PlayerResult.GainedCredits.toLocaleString("nl-NL")} credits!
        </p>

        {PlayerResult.GainedNeutronium > 0 && (
          <p style={{ color: "green", fontWeight: "bold" }}>
            You found {PlayerResult.GainedNeutronium} ({PlayerResult.GainedNeutroniumLevelBonus} extra from level bonus) Neutronium!
          </p>
        )}

        {PlayerResult.GainedSpaceDebris > 0 && (
          <p style={{ color: "green", fontWeight: "bold" }}>
            You managed to salvage {PlayerResult.GainedSpaceDebris} ({PlayerResult.GainedSpaceDebrisLevelBonus} extra from level bonus) space debris after the
            fight!
          </p>
        )}

        {PlayerResult.GainedAccuracy && <p style={{ color: "green", fontWeight: "bold" }}>You are positive that your aim has improved!</p>}

        {PlayerResult.GainedEvasion && <p style={{ color: "green", fontWeight: "bold" }}>Your skill at evading incoming attacks has improved!</p>}

        {PlayerResult.GainedWeaponsMastery && <p style={{ color: "green", fontWeight: "bold" }}>You learned how to squeeze more damage out of your weapons!</p>}

        {PlayerResult.GainedArmorMastery && <p style={{ color: "green", fontWeight: "bold" }}>You gained a deeper understanding of hull-composition!</p>}

        {PlayerResult.GainedBlueprint && (
          <p style={{ color: "blue", fontWeight: "bold" }}>Your architects finished drawing up a blueprint for your homeplanet!</p>
        )}

        {PlayerResult.GainedLevel && (
          <div>
            <br />
            <p style={{ color: "green", fontWeight: "bold" }}>You gained a level!</p>
          </div>
        )}

        {PlayerResult.GainedAnomaly && (
          <div>
            <p style={{ color: "gold", fontWeight: "bold" }}>You detected an anomaly somewhere in the vast nothingness of space!</p>
          </div>
        )}
      </div>
    );
  };

  lossTemplate = () => {
    let { TotalRoundCount } = this.context.latestBattleResult;

    if (TotalRoundCount >= 1500) {
      return (
        <div>
          <p style={{ color: "red", fontWeight: "bold" }}>
            After {TotalRoundCount} rounds your ship ran out of fuel, and you were pulled into the nearest star by pure gravitational force!
          </p>
        </div>
      );
    }

    return (
      <div>
        <p style={{ color: "red", fontWeight: "bold" }}>You lost! After {TotalRoundCount} rounds your ship sustained too much damage!</p>
      </div>
    );
  };

  selectMonster = (e, { value }) => {
    e.preventDefault();
    this.setState({ selectedEnemy: parseInt(value) });
  };

  fightMonster = () => {
    this.setState({ loading: true });

    this.context.socket.emit("SelectEnemy", { ID: this.state.selectedEnemy });

    setTimeout(() => {
      this.setState({ loading: false });
    }, 2500);
  };

  render = () => {
    let { player, latestBattleResult } = this.context;
    let { loading, selectedEnemy } = this.state;
    if (!selectedEnemy || selectedEnemy === 0) selectedEnemy = player.CurrentMonsterID;

    if (!latestBattleResult) return <div>Awaiting latest battle report...</div>;
    let { Win, PlayerResult, EnemyResult } = latestBattleResult;

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Form name="fightForm" onSubmit={this.fightMonster}>
              <p>Select your enemy:</p>
              <Input onChange={this.selectMonster} value={selectedEnemy} disabled={loading} placeholder="Pick an enemy..." />
              <Button onClick={this.fightMonster} disabled={loading} loading={loading}>
                Fight!
              </Button>
            </Form>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={8}>
            <p>{player.Username}</p>
          </Grid.Column>
          <Grid.Column width={8}>
            <p>Enemy #{EnemyResult.ID}</p>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={8}>
            <Progress inverted size="small" value={PlayerResult.HealthRemaining} total={player.ModifiedHealth}>
              {FormatThousands(PlayerResult.HealthRemaining, 2)} / {FormatThousands(player.ModifiedHealth, 2)} hp
            </Progress>

            <p>Hit chance: {PlayerResult.HitChance.toFixed(2)}%</p>

            <p>
              You managed to hit {PlayerResult.HitCount} times ({PlayerResult.CritCount} crits) and missed {PlayerResult.MissCount} times! <br /> Your average
              damage done was {Math.floor(PlayerResult.AverageDamageDone).toLocaleString("nl-NL")}!
            </p>
          </Grid.Column>
          <Grid.Column width={8}>
            <Progress inverted size="small" value={EnemyResult.HealthRemaining} total={EnemyResult.HealthTotal}>
              {FormatThousands(EnemyResult.HealthRemaining, 2)} / {FormatThousands(EnemyResult.HealthTotal, 2)} hp
            </Progress>

            <p>Hit chance: {EnemyResult.HitChance.toFixed(2)}%</p>

            <p>
              The enemy hit you {EnemyResult.HitCount} times and missed {EnemyResult.MissCount} times! <br /> Their average damage done was{" "}
              {Math.floor(EnemyResult.AverageDamageDone).toLocaleString("nl-NL")}!
            </p>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>{Win ? this.winTemplate() : this.lossTemplate()}</Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };
}
