import fetch from "@apicase/adapter-fetch";
import { ApiService } from "@apicase/services";

import { Config } from "components/utils/Config";

// Base options for all services, such as CORS and Fetch adapter
export const BaseService = new ApiService({
  adapter: fetch,
  url: `${Config.BackendDomain().protocol}${Config.BackendDomain().host}/api`,
  mode: "cors",
  headers: {
    "Content-Type": "application/json",
  },
});

BaseService.on("error", (response) => {
  // eslint-disable-next-line
  console.log("Base Service error => need to handle this gracefully (show toaster?)");
  return response;
});
