import React from "react";
import { Header, Modal } from "semantic-ui-react";

import { AuthContext } from "components/utils/AuthorizationContext/AuthorizationContext";

export class FloatingErrorToaster extends React.Component {
  static contextType = AuthContext;

  render() {
    let { error } = this.context;
    if (!error) {
      return null;
    }

    return (
      <Modal defaultOpen={true} size="small" closeIcon centered={true}>
        <Header icon="warning" content="An error occurred" color="red" />
        <Modal.Content color="red">
          <p>{error}</p>
        </Modal.Content>
      </Modal>
    );
  }
}
