import React from "react";
import { GlobalSocket } from "components/utils/GameSocketProvider/GameSocketProvider";
import { BaseService, WithAuthService } from "services";
import toast from "react-hot-toast";

const AuthContext = React.createContext({
  error: "",
  player: null,
  latestBattleResult: null,
  initialLoadDone: false,
  loggedIn: false,
});

class AuthorizationProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      login: this.login,
      logout: this.logout,
      updatePlayer: this.updatePlayer,
      setError: this.setError,
    };
  }

  setError = (error, timeout = 2000) => {
    this.setState({ error });

    setTimeout(() => {
      this.setState({ error: "" });
    }, timeout);
  };

  componentDidMount() {
    let token = localStorage.getItem("nww.token");
    if (!token) {
      // also let the game know loading is done if there is no token or login
      this.setState({ initialLoadDone: true });
      return;
    }

    WithAuthService.doRequest({
      url: "players/me",
      method: "GET",
    })
      .then((response) => {
        const socket = new GlobalSocket().connect();
        socket.on("BattleResult", this.battleResultListener);
        socket.on("Notification", this.notificationListener);

        this.setState({
          player: response.result.body,
          loggedIn: true,
          initialLoadDone: true,
          socket,
        });
      })
      .catch((error) => {
        this.setError("Could not log you back in");
        this.setState({
          initialLoadDone: true,
          loggedIn: false,
        });
      });
  }

  notificationListener = (notification) => {
    const { Type, Message } = notification;
    if (Type === "Success") toast.success(Message);
    if (Type === "Error") toast.error(Message);
  };

  battleResultListener = (result) => {
    this.setState({ latestBattleResult: result, player: result.Player });
  };

  updatePlayer = (player) => {
    if (player) {
      this.setState({
        player,
      });
    }
  };

  login = (username, password) => {
    BaseService.doRequest({
      url: "auth/login",
      method: "POST",
      body: { Username: username, Password: password },
    })
      .then((response) => {
        if (response.result.status !== 200) {
          let error = "Could not perform login, check your login credentials, or server might be down right now";
          if (response.result.body.Message) {
            error = response.result.body.Message;
          }

          this.setError(error);
          this.setState({
            initialLoadDone: true,
            loggedIn: false,
          });
          return;
        }

        localStorage.setItem("nww.token", response.result.body.Token);
        localStorage.setItem("nww.expires", response.result.body.Expires);
        localStorage.setItem("nww.refresh_token", response.result.body.RefreshToken);

        WithAuthService.doRequest({
          url: "players/me",
          method: "GET",
        })
          .then((response) => {
            if (response.result.status !== 200) {
              let error = "Could not perform login, check your login credentials, or server might be down right now";
              if (response.result.body.Message) {
                error = response.result.body.Message;
              }

              this.setError(error);
              this.setState({
                initialLoadDone: true,
                loggedIn: false,
              });
              return;
            }

            const socket = new GlobalSocket().connect();
            socket.on("BattleResult", this.battleResultListener);
            socket.on("Notification", this.notificationListener);

            this.setState({
              player: response.result.body,
              loggedIn: true,
              initialLoadDone: true,
              socket,
            });
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.log("error during profile retrieval:", error);
          });
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.log("could not do login in context:", error);
      });
  };

  logout = () => {
    localStorage.removeItem("nww.token");
    localStorage.removeItem("nww.refresh_token");
    localStorage.removeItem("nww.expires");

    if (this.state.socket) {
      this.state.socket.disconnect();
    }

    this.setState({
      error: "",
      loggedIn: false,
      socket: null,
      player: null,
      latestBattleResult: null,
    });
  };

  render() {
    if (this.state.player) {
      document.title = `${this.state.player.ActionsRemaining} - Now We Wait`;
    }

    return (
      <AuthContext.Provider
        value={{
          socket: this.state.socket,
          player: this.state.player,
          latestBattleResult: this.state.latestBattleResult,
          loggedIn: this.state.loggedIn,
          initialLoadDone: this.state.initialLoadDone,
          error: this.state.error,

          login: this.login,
          logout: this.logout,
          setError: this.setError,
          updatePlayer: this.updatePlayer,
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

const AuthorizationConsumer = AuthContext.Consumer;

export { AuthContext, AuthorizationProvider, AuthorizationConsumer };
