import React from "react";
import { AuthContext } from "components/utils/AuthorizationContext/AuthorizationContext";
import { Progress } from "semantic-ui-react";
import { FormatThousands } from "../utils/Helpers/Formatters";

export class LevelTracker extends React.PureComponent {
  static contextType = AuthContext;

  render = () => {
    const { player, loggedIn } = this.context;
    if (!loggedIn) {
      return null;
    }

    return (
      <>
        <p>
          {player.Username} - Level <strong>{player.Level}</strong>
        </p>

        <Progress
          color="yellow"
          inverted
          size="small"
          data-tooltip={`${player.Experience.toLocaleString("nl-NL")} / ${player.NextLevelAt.toLocaleString("nl-NL")}`}
          value={player.Experience}
          total={player.NextLevelAt}
        >
          {FormatThousands(player.Experience, 2)} / {FormatThousands(player.NextLevelAt, 2)} experience
        </Progress>
      </>
    );
  };
}
