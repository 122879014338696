import React from "react";
import { Confirm, Button } from "semantic-ui-react";
import { FormatThousands } from "components/utils/Helpers/Formatters";
import { AuthContext } from "components/utils/AuthorizationContext/AuthorizationContext";
import { WithAuthService } from "services";

export default class ConfirmMassOverclock extends React.PureComponent {
  static contextType = AuthContext;

  state = {
    open: false,
    dryRunResult: {},
  };

  open = () => {
    let { setError } = this.context;

    WithAuthService.doRequest({
      method: "PUT",
      url: "players/mass-overclock",
      body: {
        SelectedTier: this.props.selectedTier,
        DryRun: true,
      },
    })
      .then((response) => {
        if (response.result.status === 200) {
          this.setState({
            dryRunResult: response.result.body,
            open: true,
          });
        }

        setError(response.result.body.Message, 2000);
      })
      .catch((error) => {
        setError(error, 2000);
      });
  };

  close = () => this.setState({ open: false });

  confirm = () => {
    let { updatePlayer, setError } = this.context;

    WithAuthService.doRequest({
      method: "PUT",
      url: "players/mass-overclock",
      body: {
        SelectedTier: this.props.selectedTier,
        DryRun: false,
      },
    })
      .then((response) => {
        if (response.result.status === 200) {
          if (response.result.body.Message) {
            setError("Could not perform mass overclock: " + response.result.body.Message, 3000);
            this.props.afterConfirm();
            return;
          }

          updatePlayer(response.result.body.Player);
          this.props.afterConfirm();
        }
      })
      .catch((error) => {
        setError(error, 1500);
      });

    this.close();
  };

  cancel = () => {
    this.close();
  };

  render() {
    let result = this.state.dryRunResult;
    let content = (
      <div className="content">
        <p>
          This will cost you {FormatThousands(result.Cost, 3)} credits and upgrade a total of {result.TotalTiersGained} tiers. Are you sure?
        </p>
      </div>
    );

    return (
      <>
        <Button disabled={!this.props.selectedTier} size={this.props.size} color="green" onClick={this.open}>
          Mass overclock
        </Button>
        <Confirm open={this.state.open} onCancel={this.cancel} onConfirm={this.confirm} content={content} />
      </>
    );
  }
}
