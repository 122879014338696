import React from "react";
import { Toaster } from "react-hot-toast";
import { FooterMenu, HeaderMenu } from "components/layout";
import { ActionTracker, Chatbox, CombatLog, CurrencyTracker, FloatingErrorToaster, LevelTracker, StatTracker } from "components/ui-elements";
import { AuthorizationProvider } from "components/utils/AuthorizationContext/AuthorizationContext";
import { PrivateComponent } from "components/utils/PrivateComponent";
import { NavLink } from "react-router-dom";
import { Divider, Grid, Segment } from "semantic-ui-react";
import { Routes } from ".";

export class App extends React.Component {
  render = () => (
    <AuthorizationProvider>
      <FloatingErrorToaster />

      <Grid id="wrapper" className="compact" stackable>
        <Grid.Row>
          <Grid.Column>
            <HeaderMenu />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={3} stretched>
            <Segment inverted>
              <PrivateComponent>
                <LevelTracker />
                <Divider />
                <ActionTracker />
                <Divider />
                <StatTracker />
                <Divider />
                <CurrencyTracker />
              </PrivateComponent>
            </Segment>
          </Grid.Column>

          <Grid.Column width={10}>
            <Grid.Row>
              <Segment id="main-game-content" inverted>
                <Routes />
              </Segment>
            </Grid.Row>

            <PrivateComponent>
              <Grid.Row>
                <Chatbox />
              </Grid.Row>
            </PrivateComponent>
          </Grid.Column>

          <Grid.Column width={3} stretched>
            <Segment inverted>
              <PrivateComponent>
                <CombatLog />
                <Divider />
              </PrivateComponent>

              <NavLink to="/faq">
                <strong>We are currently in alpha! Read about what this means in our FAQ!</strong>
              </NavLink>
            </Segment>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <FooterMenu />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Toaster
        position="top-left"
        toastOptions={{
          className: "",
          duration: 4000,
          style: {
            background: "#363636",
            color: "#fff",
          },
          success: {
            duration: 2500,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
    </AuthorizationProvider>
  );
}
