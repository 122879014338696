import React from "react";
import { Accordion, Icon, Segment } from "semantic-ui-react";

import { AuthContext } from "components/utils/AuthorizationContext/AuthorizationContext";

export class FAQPage extends React.Component {
  static contextType = AuthContext;

  state = {
    activeIndex: 0,
  };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    let newIndex = activeIndex === index ? -1 : index;

    this.setState({
      activeIndex: newIndex,
    });
  };

  render() {
    const { activeIndex } = this.state;

    return (
      <Segment inverted>
        <Accordion inverted>
          <Accordion.Title active={activeIndex === 0} index={0} onClick={this.handleClick}>
            <Icon name="dropdown" />
            We are currently running an alpha release version of the game!
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 0}>
            <p>
              This game is currently in alpha. This means you can expect bugs. On the plus side; we are constantly releasing new features. We are planning on
              resetting all players before we go live for real. One of the reasons we do this is because we didn't have anti-botting systems in place from the
              start. This means that some people used to bot, which was fine for helping us balance the game at the start. We'll actively combat botting and
              cheating in the future, but it isn't our top priority for the alpha. It wouldn't be fair to people who either don't want or don't know how to bot
              if we didn't reset.
            </p>
          </Accordion.Content>

          <Accordion.Title active={activeIndex === 1} index={1} onClick={this.handleClick}>
            <Icon name="dropdown" />
            What is Now we Wait?
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 1}>
            <p>
              Now we Wait is a permanent browser based, space-themed, progression game where you get stronger by killing enemies and spending your hard-earned
              currencies on upgrades.
            </p>
          </Accordion.Content>

          <Accordion.Title active={activeIndex === 2} index={2} onClick={this.handleClick}>
            <Icon name="dropdown" />
            What is the tick time in this game?
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 2}>
            <p>
              Ticks are spaced three seconds apart. During each tick, as long as you have actions remaining, you execute a single fight. When you start the
              game, you start with 300 actions. This amount is upgradable through various means. With the starting 300 actions you'll have to refresh your
              actions every 15 minutes.
            </p>
          </Accordion.Content>

          <Accordion.Title active={activeIndex === 3} index={3} onClick={this.handleClick}>
            <Icon name="dropdown" />
            What are credits, space debris and neutronium?
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 3}>
            <p>
              These are the three currencies that are currently available in the game. You can use these currencies to buy stuff, such as boosts. Later on,
              players will be able to trade ship modules in the market place, for example.
            </p>
          </Accordion.Content>

          <Accordion.Title active={activeIndex === 4} index={4} onClick={this.handleClick}>
            <Icon name="dropdown" />
            How do I get credits?
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 4}>
            <p>
              When you win a battle you are awarded with a certain amount of credits and experience. The amount depends on the level of the enemy you beat, as
              well as various boosts you can acquire...
            </p>
          </Accordion.Content>

          <Accordion.Title active={activeIndex === 5} index={5} onClick={this.handleClick}>
            <Icon name="dropdown" />
            How do I get space debris or neutronium?
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 5}>
            <p>
              When you win a battle, you have a small chance of being awarded with a certain amount of space debris or neutronium. The amount is random for
              each. You also get rewarded a bonus amount which depends on your level, and lastly there are various boosts you can acquire to upgrade quantity
              and frequency of neutronium as well as space debris drops.
            </p>
          </Accordion.Content>

          <Accordion.Title active={activeIndex === 6} index={6} onClick={this.handleClick}>
            <Icon name="dropdown" />
            How do I level up my stats?
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 6}>
            <p>Stat level-ups are random drops that you can be awarded when you win a battle.</p>
          </Accordion.Content>

          <Accordion.Title active={activeIndex === 7} index={7} onClick={this.handleClick}>
            <Icon name="dropdown" />
            So this game is set in space?
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 7}>
            <p>
              Yes! We are currently hard at work, since this is only an alpha version. In the near future we want to add more space related references, as well
              as a story which is set in space.
            </p>
          </Accordion.Content>

          <Accordion.Title active={activeIndex === 8} index={8} onClick={this.handleClick}>
            <Icon name="dropdown" />
            These enemies sure have weird names...
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 8}>
            <p>
              The names of these species are based on ‘scientific names’. The first part is a capitalized Genus of actual animals that exist on planet earth.
              The second part is the species name. Besides these species there’s also some groups, consisting of multiple species. These groups work together to
              be stronger than their individual parts.
            </p>
          </Accordion.Content>

          <Accordion.Title active={activeIndex === 9} index={9} onClick={this.handleClick}>
            <Icon name="dropdown" />I just registered, now what?
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 9}>
            <p>
              Look for the blue text that says 'Renew'. It's hard to miss. It's right under the progress bar that shows your remaining amount of actions. Press
              on the 'Renew' button and your actions should start. When your actions run out you'll have to click 'Renew' again. Alternatively, pressing the "R"
              key on your keyboard will refresh your actions as well.
            </p>
          </Accordion.Content>

          <Accordion.Title active={activeIndex === 10} index={10} onClick={this.handleClick}>
            <Icon name="dropdown" />
            Do actions keep running when I'm in another tab?
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 10}>
            <p>
              Actions are completely server-side. This is a nerdy way of saying; yes. Your actions will keep running when you're in another tab, as well as when
              you close the tab or when you shut down your pc.
            </p>
          </Accordion.Content>

          <Accordion.Title active={activeIndex === 11} index={11} onClick={this.handleClick}>
            <Icon name="dropdown" />
            Which enemy should I be fighting?
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 11}>
            <p>
              We can't give you a concrete answer here, sorry. What we can say is this; an enemy with a higher difficulty will have better drops (both
              experience and credits). This means that beating enemy #2 consistently will give more experience per second than beating enemy #1 consistently. So
              try to find an enemy that you can consistently beat, but that isn't too easy to beat.
            </p>
          </Accordion.Content>

          <Accordion.Title active={activeIndex === 12} index={12} onClick={this.handleClick}>
            <Icon name="dropdown" />
            Ok... so I've been battling for a while. Now what?
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 12}>
            <p>
              When you battle enemies random drops start appearing. Among these drops are credits, space debris, neutronium and blueprints. These are basically
              the currencies in this game. Using these currencies you can upgrade either your stats or your chances to find drops. These upgrades you can find
              on the Homeplanet and Boosts pages.
            </p>
          </Accordion.Content>

          <Accordion.Title active={activeIndex === 13} index={13} onClick={this.handleClick}>
            <Icon name="dropdown" />
            How do I send other people credits, space debris or neutronium?
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 13}>
            <p>
              Using the chat command <code>/wire</code> (or the aliases, <code>/send</code> and
              <code>/give</code>) you can trade currencies with other players.
              <br />
              Examples:
              <pre>/wire PlayerName 1000 neutronium /send PlayerName 1k neutronium /give PlayerName 1m debris /wire PlayerName 1b credits</pre>
            </p>
          </Accordion.Content>
        </Accordion>
      </Segment>
    );
  }
}
