import React from "react";

import { AuthContext, AuthorizationConsumer } from "components/utils/AuthorizationContext/AuthorizationContext";

export class PrivateComponent extends React.Component {
  static contextType = AuthContext;

  render() {
    let children = this.props.children;

    return (
      <AuthorizationConsumer>
        {({ loggedIn, initialLoadDone }) => {
          if (!initialLoadDone) {
            return <p>Loading...</p>;
          }

          if (loggedIn === true) {
            return children;
          }

          return null;
        }}
      </AuthorizationConsumer>
    );
  }
}
